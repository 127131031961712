import {Component} from '@angular/core';
import {AuthService} from './auth/auth.service';
import {OktaAuthService} from '@okta/okta-angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'detroit-ui';
  showLoader = false;
  isAuthenticated = false;
  userName = '';

  constructor(private authService: AuthService, public oktaAuth: OktaAuthService, public router: Router) {
    this.oktaAuth.$authenticationState.subscribe(isAuthenticated => this.isAuthenticated = isAuthenticated);
  }

  async login() {
    if (this.oktaAuth.isLoginRedirect()) {
      console.log('Logged in redirect');
      let oktaTokenStorage = localStorage.getItem('okta-token-storage');
      let jsonOktaTokenStorage = JSON.parse(oktaTokenStorage);
      // let role = jsonOktaTokenStorage.accessToken.claims.det_roles;
      localStorage.setItem('Role', 'Admin');
      await this.oktaAuth.handleLoginRedirect();
      await this.router.navigateByUrl('/dashboard/platform');
    } else if (!await this.oktaAuth.isAuthenticated()) {
      this.oktaAuth.signInWithRedirect({
        originalUri: '/dashboard/platform'
      }).then(r => {
        console.log('Logged in');
      });
    } else {
      this.isAuthenticated = await this.oktaAuth.isAuthenticated();
      console.log('Authenticated :' + this.isAuthenticated);
      const accessToken = this.oktaAuth.getAccessToken();
      const userClaims = await this.oktaAuth.getUser();
      this.userName = userClaims.name;
      if (this.userName) {
        console.log('Got username from Okta');
        await this.router.navigateByUrl('/dashboard/platform');
      }
      localStorage.clear();
      localStorage.setItem('Role', 'Admin');
      this.showLoader = false;
      let count = 0;
      let negCount = 0;
      this.authService.startLoader.subscribe(
        (value) => {
          if (value == true) {
            count++;
            setTimeout(() => {
              this.showLoader = value;
            }, 100);
          } else if (value == false) {
            negCount++;
            if (count === negCount || count < negCount) {
              setTimeout(() => {
                this.showLoader = value;
              }, 100);
              count = 0;
              negCount = 0;
            }
          }
        }
      );
    }
  }

  async logout() {
    await this.oktaAuth.signOut();
    this.router.navigateByUrl('/');
  }

  async ngOnInit() {
    this.login();
  }
}

