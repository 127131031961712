<div class = "modal-header">
    <div>	
       Confirmation
    </div>
</div>
<div class="confirm-container">
    <div mat-dialog-content class="mt-3">
        <p>{{message}}</p>
    </div>
          
    <div mat-dialog-actions class="confirm-btn float-right mb-2">
        <button mat-raised-button  class="btn-cancel mr-2" (click)="onDismiss()">No</button>
        <button mat-raised-button class="btn-save" (click)="onConfirm()">Yes</button>
    </div>
</div>