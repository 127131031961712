import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoggerModule } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { DeactivateGuard } from './dashboard/inventory/inventory.component';
import { PlatformDeactivateGuard } from './dashboard/platform/platform.component';
import {LocationDeactivateGuard } from './dashboard/location/location.component'
import { DeviceDeactivateGuard } from './dashboard/device/device.component';
import { RmaDeactivateGuard } from './dashboard/rma/rma.component';
import { StatusDeactivateGuard } from './dashboard/status/status.component';
import { ShippingDeactivateGuard } from './dashboard/shipping/shipping.component';

const config = {
  clientId: '0oaw7ntnsaTOSLReE0h7',
  issuer: 'https://echostar.oktapreview.com/oauth2/default',
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email', 'groups', 'phone', 'det_roles'],
  pkce: true
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    NgbModule,
    HttpClientModule,
    NgxTrimDirectiveModule,
    OktaAuthModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
      disableConsoleLogging: false,
    })
  ],
  providers: [ DeactivateGuard, LocationDeactivateGuard, PlatformDeactivateGuard,
    DeviceDeactivateGuard,
    RmaDeactivateGuard,
    StatusDeactivateGuard,
    ShippingDeactivateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: OKTA_CONFIG, useValue: config
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
