import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { JSON2SheetOpts } from 'xlsx/types';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDailogComponent, ConfirmDialogModel } from '../../shared/confirm-dailog/confirm-dailog.component';
import { CommonService } from '../../services/common.service';
import { StatusService } from 'src/app/services/status.service';
import { Observable } from "rxjs";
import { CanDeactivate } from '@angular/router';

export class StatusDeactivateGuard implements CanDeactivate<StatusComponent> {
  canDeactivate(component: StatusComponent) {
    if(component.canD_flag){
      return component.canDeactivate()
    }
    else
      return true;
  }
}

export interface Status{
  "statusId": number,
  "statusKey": String,
  "statusValue": String,
  "addedOn": String,
  "updatedOn": String
}

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  sortedData: Status[] = [];
  perstineStatusData: any[] = [];
  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";
  searchInput: string = '';
  deleteData:any[] = [];
  statusData: any[] = [];

  showError: boolean = false;
  errorMessage: any = [];
  errorCount: number = 0;
  dataSubmitted: boolean = false;
  isUpdated: boolean = false;
  isAdded: boolean = false;
  role : string = '';
  canD_flag : boolean = false;

  constructor(private logger: NGXLogger, public dialog: MatDialog , private commonService: CommonService, private statusService : StatusService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getStatusData();
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }
  setPageValue() {
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount, this.statusData.length)
  }
  sortData(sort: Sort) {
    const data = this.statusData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'statusKey': return this.commonService.compare(a.statusKey.toUpperCase(), b.statusKey.toUpperCase(), isAsc);
        case 'statusValue': return this.commonService.compare(a.statusValue.toUpperCase(), b.statusValue.toUpperCase(), isAsc);
        case 'addedOn': return this.commonService.compareDate(a.addedOn, b.addedOn, isAsc);
        case 'updatedOn': return this.commonService.compareDate(a.updatedOn, b.updatedOn, isAsc);
        default: return 0;
      }
    });
  }

  addNew() {
    this.canD_flag=true;
    // this.searchInput = '';
    var newObj = {
      "statusId": '',
      "statusKey": "",
      "statusValue": "",
      "addedOn": "",
      "updatedOn": ""
    };
    this.statusData.push(newObj);
    this.sortedData = this.statusData.slice();
    if (this.pageSizeCount !== "pageAll") {
      this.page = this.commonService.pageChangeAddNew(this.pageSizeCount, this.statusData.length);
    } else {
      this.pageSize = this.statusData.length;
    }
  }

  searching(event) {
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.perstineStatusData));
    this.statusData = [];
    this.sortedData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.statusId;
      delete newObj.originalStatusId;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.statusData.push(data);
      }
    })
    this.sortedData = this.statusData.slice();
  }

  confirmDeleteDialog(data, index): void {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete the status data?');
    const dialogRef = this.dialog.open(ConfirmDailogComponent, {
      maxWidth: '500px',
      data: dialogData,
      panelClass: 'dialog-box'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        if(!data.statusId){
          this.statusData.splice(index, 1);
          this.sortedData.splice(index, 1);
          if(this.pageSize > 20  ){
            this.pageSize = this.statusData.length;
          }
        }

        if (data.statusId) {
          this.statusService.deleteStatusDetails(data.statusId).subscribe(
            async (result: any) => {
              await result;
              this.dataSubmitted = true;
              this.statusData.splice(index, 1);
              this.sortedData.splice(index, 1);
              if(this.pageSize > 20  ){
                this.pageSize = this.statusData.length;
              }
              let prestineIndex = this.perstineStatusData.findIndex((prestinState)=>prestinState.statusId == data.statusId);
              if(prestineIndex !== -1){
                this.perstineStatusData.splice(prestineIndex, 1);
              }
            },
            async (error: any) => {
              await error;
              this.logger.error(error);

              this.errorCount++;
              if(error.responseCode){
                this.showError = true;
                this.errorMessage.push(error.responseMessage);
              }
            }
          )
        }
      }
    });
  }

  export() {
    let data = JSON.parse(JSON.stringify(this.perstineStatusData));
    let exportData = data.map( s => ({
      "Status Key":s.statusKey,
      "Status Value":s.statusValue,
      "Added on":s.addedOn,
      "Last Updated On":s.updatedOn,
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'status.xlsx');
  }

  getStatusData(){
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.searchInput = '';
        this.searching(this.searchInput);
        this.statusData = data.responseData.status;
        this.statusData.forEach((loc)=>{
          loc.originalStatusId = loc.statusId;
        });
        this.sortedData = this.statusData.slice();
        this.perstineStatusData = JSON.parse(JSON.stringify(this.statusData));
      }
    }, (error)=>{
        this.logger.error(error);
        if(error.responseCode){
          this.showError = true;
          this.errorMessage.push(error.responseMessage);
        }
        this.errorCount++;
    })
  }

  // method created to push updated values in an obj
  focusoutFunc(data, index) {
    this.canD_flag=true;
    // this.searchInput = '';
    let checkData = [];
    if(data.statusId !== undefined || data.statusId !== null){
      checkData = this.perstineStatusData.filter((item)=>{
        return data.statusId === item.statusId;
      })
      if(this.statusData[index].isUpdated){
        delete this.statusData[index].isUpdated;
      }
      if(checkData.length){
        if(checkData[0].isUpdated){
          delete checkData[0].isUpdated;
        }
      }
      if(JSON.stringify(data) !== JSON.stringify(checkData[0])) {
        this.statusData[index].isUpdated = true;
      }
    }
  }

  saveSatusData(){
    this.canD_flag=false;
    if(this.role != 'DET_Readonly'){
      this.searchInput = '';
      let updatedData = [];
      let newData = [];
      this.showError = false;
      this.errorMessage = [];
      let errorMsg;
      this.errorCount = 0;
      this.dataSubmitted = false;

      for(let i=0; i<this.statusData.length; i++){
        if(this.statusData[i].statusKey === "" || this.statusData[i].statusValue === ""){
          errorMsg = "You need to fill the complete data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(this.statusData[i].isUpdated && this.statusData[i].statusId) {
          updatedData.push(this.statusData[i])
        }
        if(!this.statusData[i].statusId) {
          newData.push(this.statusData[i])
        }
      }

        // update service --------------------------------------------
      for (let i = 0; i < updatedData.length; i++) {
        let obj = {
          "statusKey" : updatedData[i].statusKey,
          "statusValue" : updatedData[i].statusValue
        }

        this.statusService.updateStatusDetails(obj, updatedData[i].statusId).subscribe(
          async (data: any) => {
            await data;
            for (let j = 0; j < this.statusData.length; j++) {
              if (data.responseData.statusId === this.statusData[j].statusId) {
                this.statusData[j] = data.responseData;
                this.statusData[j].originalStatusId = this.statusData[j].statusId;
                this.sortedData = this.statusData.slice();
                this.perstineStatusData = JSON.parse(JSON.stringify(this.statusData));
              }
            }
            this.dataSubmitted = true;
            this.isUpdated = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isUpdated = true;
          }
        )
      }
      updatedData = [];

      // add service -----------------------------------------------------------------------
      for (let i = 0; i < newData.length; i++) {
        let obj = {
          "statusKey" : newData[i].statusKey,
          "statusValue" : newData[i].statusValue
        };

        this.statusService.addStatusDetails(obj).subscribe(
          async (data: any) => {
            await data
            this.statusData = this.statusData.filter(function (data) {
              if (data.statusId) {
                return data;
              }
            })

            let obj = data.responseData;
            obj.originalStatusId = obj.statusId;
            this.statusData.push(obj);
            this.sortedData = this.statusData.slice();
            this.perstineStatusData = JSON.parse(JSON.stringify(this.statusData));
            this.dataSubmitted = true;
            this.isAdded = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isAdded = true;
          }
        )
      }
      newData = [];
    }
  }

  cancel(){
    this.canD_flag=false;
    this.showError = false;
    this.errorMessage = [];
    this.errorCount = 0;
    this.dataSubmitted = false;
    this.sortedData = [];
    this.perstineStatusData = [];
    this.statusData = [];
    this.deleteData = [];
    this.getStatusData();
  }

  closeError(){
    this.errorMessage = [];
    this.showError = false;
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    //return confirm("Are you sure you want to leave the inventory data?")
    let thisObj = this;
    var confirmDialog = async function(){
      const dialogData = new ConfirmDialogModel('This page contains unsaved data. are you sure you want to leave the page?');
      const dialogRef = thisObj.dialog.open(ConfirmDailogComponent, {
        maxWidth: '500px',
        data: dialogData,
        panelClass: 'dialog-box'
      });
      return await dialogRef.afterClosed().toPromise();
    }
    return confirmDialog();
  }
}
