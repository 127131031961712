import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }
  
  setPageValue(pageSize,arrLength){
    if(pageSize === "page10"){
      return 10;
    }
    else if(pageSize === "page20"){
      return 20;
    }
    else{
      return arrLength;
    }
  }

  pageChangeAddNew(pageSizeCount, dataLength) {
    if (pageSizeCount === 'page10') {
      let pageValue = dataLength / 10;
      if (dataLength % 10 > 0) {
        return pageValue + 1;
      }
      else {
        return pageValue;
      }
    }
    else if (pageSizeCount === 'page20') {
      let pageValue = dataLength / 20;
      if (dataLength % 20 > 0) {
        return pageValue + 1;
      }
      else {
        return pageValue;
      }
    }
    else {
      return 1;
    }
  }

  public static status = [
    { id : 'ACTIVE', name : 'Active'},
    { id : 'INACTIVE', name : 'Inactive'}
  ];

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // date comparison
  compareDate(a: number | string, b: number | string, isAsc: boolean){
    var p = new Date(a);
    var q = new Date(b);
    return (p < q ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortArr(arr,prop:string){
    let aValue = '';
    let bValue = '';
    if(arr.length && prop){
      return arr.sort((a:any,b:any)=>{
        if(a[prop] !== null && a[prop] !== undefined && b[prop] !== null && b[prop] !== undefined ){
          aValue = a[prop].toString().toUpperCase();
          bValue = b[prop].toString().toUpperCase();
        }
        if(!aValue){
          aValue = "";
        }
        if(!bValue){
          bValue = "";
        }

        if(aValue < bValue){
          return -1;
        }
        else if(aValue > bValue){
          return 1;
        }
        else {
          return 0;
        }
      })
    }
    if(!arr.length){
      return arr
    }
  }
  
}
