import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { InventoryService } from 'src/app/services/inventory.service';

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.css']
})
export class ImportFileComponent implements OnInit {

  details: any = {};
  disbaleBtn: boolean = false;
  filenameError : boolean = false;
  fileTypeError : boolean = false;
  responseError : any[] = [];
  finalErrorMsg : any[] = [];
  serverError : string = '';
  successMessage : boolean = false;

  constructor(public dialogRef: MatDialogRef<ImportFileComponent>,@Optional() @Inject(MAT_DIALOG_DATA) public data: any, private logger: NGXLogger, private inventoryService : InventoryService) {this.details = data}

  ngOnInit() {
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  fileUpload(input: HTMLInputElement){
    if(input.value){
      this.disbaleBtn = true;
      this.serverError = '';
      this.finalErrorMsg = [];
      this.filenameError = false;
      this.fileTypeError= false;
      this.successMessage = false;
    }
  }
  attachFileFunc(input: HTMLInputElement) {
    this.serverError = '';
    this.successMessage = false;
    this.finalErrorMsg = [];
    this.filenameError = false;
    this.fileTypeError= false;
    let file = input.files[0];
    let fileType = file.name.split('.')

    if(fileType[fileType.length -1] == 'xlsx' || fileType[fileType.length -1] == 'xls' || fileType[fileType.length -1] == 'csv'){
      this.fileTypeError = false;
      if(file.name.toUpperCase().includes(this.details.filename.toUpperCase())){
        let formData = new FormData();
        formData.append(this.details.fileProperty,file)
        this.inventoryService.importFileData(formData).subscribe((data : any)=>{
          if(data){
            if(data.responseMessage == 'OK'){
              this.successMessage = true;
            }
          }
        }, (error)=>{
          if(error.responseCode == "ERR_DTR_1002"){
            this.responseError = error.responseData;
            let finalArr = [];
            this.responseError.forEach(element => {
              let errorArr = [];
              for (const [key, value] of Object.entries(element)) {
                errorArr.push({
                  sNo : key,
                  errorMsg : value
                });
              }
              finalArr.push(...errorArr);
            });
            this.finalErrorMsg = finalArr;
          } else {
            this.logger.error(error);
            if(error.responseCode){
            this.serverError = error.responseMessage;
            }
          }
        })
      } else {
        this.filenameError = true;
      }
    } else {
      this.fileTypeError = true;
    }
  }

  cancelUpload(input: HTMLInputElement){
    input.value = '';
    this.disbaleBtn = false;
    this.filenameError = false;
    this.fileTypeError = false;
    this.serverError = '';
    this.finalErrorMsg = [];
    this.successMessage = false;
  }

}
