import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringFormatterService {

  constructor() { }

  // encode pathParam
  public format(val,list){
    for(let i=0; i<list.length; i++){
      list[i] = encodeURIComponent(list[i])
      val=  val.replace('{'+i+'}',list[i]);
    } 
    return val;
  }

  // encode queryParam
  public queryParam(url,params){
    url +='?';
    params.forEach(element => {
      url += element.key + '=' +encodeURIComponent(element.value) + '&';
    });
    return url.slice(0,-1);
  }
}
