import { Inject } from '@angular/core';
import { Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { CommonService } from 'src/app/services/common.service';
import { LocationService } from 'src/app/services/location.service';
import { PlatformService } from 'src/app/services/platform.service';
import { StatusService } from 'src/app/services/status.service';

export interface Location{
  "locationId": number,
  "platformId": number,
  "locationName": String,
  "operationStatus": number,
  "physicalAddress": String,
  "shippingAddress": String,
  "phoneNo": String,
  "website": String,
  "addedOn": String,
  "updatedOn": String
}

@Component({
  selector: 'app-location-popup',
  templateUrl: './location-popup.component.html',
  styleUrls: ['./location-popup.component.css']
})
export class LocationPopupComponent implements OnInit {

  locationData : any[] = [];
  sortedData : Location[] = [];
  searchInput : String = '';
  perstineLocationData : any[] = [];
  platformData : any[] = [];
  status : any[] = [];
  selectedLocation = {
  };
  statusData : any[] = [];

  platformInfo : string = '';
  serialNumber : string = '';
  partNumber : string = '';

  locationInfo : string = '';

  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";
  role : string = '';

  constructor(public dialogRef: MatDialogRef<LocationPopupComponent>,@Optional() @Inject(MAT_DIALOG_DATA) public data: any, private logger: NGXLogger, private locationService : LocationService, private commonService : CommonService, private platformService : PlatformService, private statusService : StatusService) { this.locationInfo = data.locationInfo; this.platformInfo = data.platform; this.serialNumber = data.serialNo; this.partNumber = data.partNo }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getStatusData();
    this.getLocation();
  }

  cancel(){
    this.dialogRef.close();
  }

  getLocation(){
    this.selectedLocation = {};
    this.locationService.getLocationDetails().subscribe((data : any)=>{
      if(data){
        this.getPlatformDetails();
        this.logger.debug("Location data: ",data);
        this.searchInput = '';
        data.responseData.locations.forEach(element => {
          if(element.platformId == this.platformInfo){
            this.locationData.push(element);
          }
        });
        if(this.locationData.length){
          this.locationData.forEach((loc)=>{
            loc.originalLocationId = loc.locationId;
          });
          if(this.locationInfo){
            let index = this.locationData.findIndex((data)=>data.locationId == this.locationInfo);
            if(index !== -1){
              let newLocationInfo = this.locationData.splice(index, 1);
              this.locationData.unshift(newLocationInfo[0]);
              this.selectedLocation = newLocationInfo[0];
            }
          }
          this.sortedData = this.locationData.slice();
          this.perstineLocationData = JSON.parse(JSON.stringify(this.locationData));
        }
      }
    }, (error)=>{
      this.logger.error(error);
    });
  }

    // sorting
  sortData(sort: Sort) {
    const data = this.locationData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'locationName': return this.commonService.compare(a.locationName.toUpperCase(), b.locationName.toUpperCase(), isAsc);
        case 'platformId' : return this.commonService.compare(a.platformId, b.platformId, isAsc);
        case 'operationStatus': return this.commonService.compare(a.operationStatus, b.operationStatus, isAsc);
        case 'physicalAddress': return this.commonService.compare(a.physicalAddress.toUpperCase(), b.physicalAddress.toUpperCase(), isAsc);
        case 'shippingAddress': return this.commonService.compare(a.shippingAddress, b.shippingAddress, isAsc);
        case 'phoneNo': return this.commonService.compare(a.phoneNo.toUpperCase(), b.phoneNo.toUpperCase(), isAsc);
        case 'website': return this.commonService.compare(a.website, b.website, isAsc);
        case 'addedOn': return this.commonService.compareDate(a.addedOn, b.addedOn, isAsc);
        case 'updatedOn': return this.commonService.compareDate(a.updatedOn, b.updatedOn, isAsc);
        default: return 0;
      }
    });
  }

  searching(event) {
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.perstineLocationData));
    this.locationData = [];
    this.sortedData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.locationId;
      delete newObj.originalLocationId;
      delete newObj.platformId;
      delete newObj.operationStatus;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.locationData.push(data);
        if(this.locationInfo){
          let index = this.locationData.findIndex((data)=>data.locationId == this.locationInfo);
          if(index !== -1){
            let newLocationInfo = this.locationData.splice(index, 1);
            this.locationData.unshift(newLocationInfo[0]);
            this.selectedLocation = newLocationInfo[0];
          }
        }
      }
    });
    this.sortedData = this.locationData.slice();
  }

  saveLocation(){
    if(this.role != 'DET_Readonly'){
      this.dialogRef.close(this.selectedLocation);
    }
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }

  setPageValue() {
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount, this.locationData.length)
  }

  getPlatformDetails(){
    this.platformService.getPlatformDetails().subscribe((data: any)=>{
      if(data){
        this.logger.debug("Platform data: ",data);
        this.platformData = data.responseData.platforms;
        this.commonService.sortArr(this.platformData, 'platform');
      }
    }, (error)=>{
      this.logger.error(error);
    });
  }

  getStatusData(){
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.statusData = data.responseData.status;
        this.statusData.forEach(element => {
          if(element.statusKey.toUpperCase() == 'OPERATIONAL'){
            this.status.push(element);
          }
        });
      }
    }, (error)=>{
        this.logger.error(error);
    })
  }

}
