import { Injectable } from '@angular/core';
import { Constants } from '../constants/constants';
import { HttpMiddleLayerService } from './http-middle-layer.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private middleLayer: HttpMiddleLayerService) { }

  getInventoryDetails(id){
    const pathParam = [id];
    const url = Constants.API.url +`inventories/{0}`;
    return  this.middleLayer.get(url, null, pathParam, null);
  }

  addInventoryDetails(data){
    const url = Constants.API.url+`addInventory`;
    return  this.middleLayer.post(url,data,null,null,null);
  }

  updateInventoryDetails(data,id){
    const pathParam = [id];
    const url = Constants.API.url + 'inventory/{0}';
    return  this.middleLayer.put(url,data,null,pathParam,null)
  }

  deleteInventoryDetails(id){
    const pathParam = [id];
    const url = Constants.API.url + 'inventory/{0}';
    return  this.middleLayer.delete(url,null,pathParam,null)
  }

  getRmaDetails(id){
    const pathParam = [id];
    const url = Constants.API.url +`rmas/{0}`;
    return  this.middleLayer.get(url, null, pathParam, null);
  }

  deleteRmaDetails(id){
    const pathParam = [id];
    const url = Constants.API.url + 'rma/{0}';
    return  this.middleLayer.delete(url,null,pathParam,null)
  }

  addRmaDetails(data){
    const url = Constants.API.url+`rma`;
    return  this.middleLayer.post(url,data,null,null,null);
  }

  updateRmaDetails(data,id){
    const pathParam = [id];
    const url = Constants.API.url + 'rma/{0}';
    return  this.middleLayer.put(url,data,null,pathParam,null)
  }

  getShippingDetails(id){
    const pathParam = [id];
    const url = Constants.API.url +`shipping/{0}`;
    return  this.middleLayer.get(url, null, pathParam, null);
  }

  deleteShippingDetails(id){
    const pathParam = [id];
    const url = Constants.API.url + 'shipping/{0}';
    return  this.middleLayer.delete(url,null,pathParam,null)
  }

  addShippingDetails(data){
    const url = Constants.API.url+`shipping`;
    return  this.middleLayer.post(url,data,null,null,null);
  }

  updateShippingDetails(data,id){
    const pathParam = [id];
    const url = Constants.API.url + 'shipping/{0}';
    return  this.middleLayer.put(url,data,null,pathParam,null)
  }

  importFileData(file){
    const url = Constants.API.url+`upload`;
    return  this.middleLayer.post(url,file,null,null,null);
  }

  getAllRma(){
    const url = Constants.API.url +`allRmas`;
    return  this.middleLayer.get(url, null, null, null);
  }

  getAllShipping(){
    const url = Constants.API.url +`allShippings`;
    return  this.middleLayer.get(url, null, null, null);
  }

  checkInventory(partNo,serialNo){
    const pathParam = [partNo, serialNo];
    const url = Constants.API.url +'inventory/partNo/{0}/serialNo/{1}';
    return  this.middleLayer.get(url, null, pathParam, null);
  }
}
