<div  class="row ml-0 mr-0 application-banner">
    <a class="p-3">
      <img src="assets/images/hughes_logo_2.PNG">
    </a>
    <h2 class="p-3">DETROIT REGIONAL MANAGEMENT CENTER - OPERATIONS</h2>
    <button class="p-3 logout-button" (click)="logout()">Logout</button>
</div>
<div class="row ml-0 mr-0 myNavigatioBar">
  <button mat-flat-button routerLink="/dashboard/platform" routerLinkActive="active-class">Platform</button>
  <button mat-flat-button routerLink="/dashboard/location" routerLinkActive="active-class">Location</button>
  <button mat-flat-button routerLink="/dashboard/device" routerLinkActive="active-class">Device</button>
  <button mat-flat-button routerLink="/dashboard/inventory" routerLinkActive="active-class">Inventory</button>
  <button mat-flat-button routerLink="/dashboard/status" routerLinkActive="active-class">Status</button>
  <button mat-flat-button routerLink="/dashboard/rma" routerLinkActive="active-class">RMA</button>
  <button mat-flat-button routerLink="/dashboard/shipping" routerLinkActive="active-class">Shipping</button>

</div>
