import { Injectable } from '@angular/core';
import { Constants } from '../constants/constants';
import { HttpMiddleLayerService } from './http-middle-layer.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private middleLayer: HttpMiddleLayerService) { }

  getLocationDetails(){
    const url = Constants.API.url +`locations`;
    return  this.middleLayer.get(url, null, null, null);
  }

  addLocationDetails(data){
    const url = Constants.API.url + `addLocation`;
    return  this.middleLayer.post(url,data,null,null,null);
  }

  updateLocationDetails(data,id){
    const pathParam = [id];
    const url = Constants.API.url + 'location/{0}';
    return  this.middleLayer.put(url,data,null,pathParam,null)
  }

  public static operationalStatus = [
    {id : "ACTIVE", name :"Active"},
    {id : "DECOMMISSIONED", name :"Decommissioned"}
  ];
}
