<div class="modal-header">
    <div>
        History
    </div>
    <span (click)="cancel()" class="close">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"
            width="26px" height="26px" fill="#fff">
            <path
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
        </svg>
    </span>
</div>
<div class="pb-4">
    <mat-card class="p-0 pb-4">
        <div class="col-md-12 rmaWrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="table-top-header">
                        <p class="float-left mt-1 m-0">RMA</p>
                        <span class="float-right">
                            <input type="text" class= "mr-4" id="inputVal" placeholder="Search"
                                title="Type in a name" [(ngModel)]="searchInput" (keyup)="searchingRmaData($event.target.value)">
                            <button mat-button class="tableHead-addButton mr-2" [disabled]="prestineRmaData.length == 0" (click)="exportRmaData()">
                                Export
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="tableOverflow">
            <table class="table dt-table" id="dataTable"> 
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Part No.</th>
                        <th>Serial No.</th>
                        <th>RMA Status</th>
                        <th >HNS RMA</th>
                        <th >Manufacturer RMA</th>
                        <th >Ticket No.</th>
                        <th>Platform</th>
                        <th>Description</th>
                        <th>Fault</th>
                        <th>Failure Location</th>
                        <th>Note</th>
                        <th >Added On</th>
                        <th>Last Updated On</th>
                    </tr>
                </thead>
                <tbody class="locDataTable">
                    <tr *ngFor="let rma of rmaData | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index ">
                        <td>{{(page-1) * pageSize + (i+1)}}</td>
                        <td>{{partNo}}</td>
                        <td>{{serialNo}}</td>
                        <td>
                            <span *ngFor="let status of rmaStatus">
                                <span *ngIf="status.statusId == rma.rmaStatusId">{{status.statusValue}}</span>
                            </span>
                        </td>
                        <td>{{rma.hnsRma}}</td>
                        <td>{{rma.mfgRma}}</td>
                        <td>{{rma.ticketNo}}</td>
                        <td>{{platformName}}</td>
                        <td>{{inventoryDesc}}</td>
                        <td>{{rma.fault}}</td>
                        <td>
                            <span>
                                <span *ngFor="let loc of locationData">
                                    <span *ngIf="loc.locationId == rma.location">{{platformName}},  {{loc.locationName}}</span>
                                </span>
                            </span>
                        </td>
                        <td>{{rma.note}}</td>
                        <td>{{rma.createdDate | date : 'MM/dd/yyyy HH:mm:ss'}}</td>
                        <td>{{rma.lastStmtDate | date : 'MM/dd/yyyy HH:mm:ss'}}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <div *ngIf="rmaData.length == 0" class="no-data pb-2">
                No Data Available
            </div>
        </div>
    </mat-card>
</div>
<div class="pb-4">
    <mat-card class="p-0 pb-4">
        <div class="col-md-12 shipWrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="table-top-header">
                        <p class="float-left mt-1 m-0">Shipping</p>
                        <span class="float-right">
                            <input type="text" class= "mr-4" id="inputVal1" placeholder="Search"
                                title="Type in a name" [(ngModel)]="searchShippingInput" (keyup)="searchingShippingData($event.target.value)">
                            <button mat-button class="tableHead-addButton mr-2" [disabled]="perstineShippingData.length == 0" (click)="exportShippingData()">
                                Export
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="tableOverflow">
            <table class="table dt-table" id="dataTable"> 
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Part No.</th>
                        <th>Serial No.</th>
                        <th >Ticket No.</th>
                        <th>Description</th>
                        <th >Date Shipping</th>
                        <th>Shipped</th>
                        <th >Tracking</th>
                        <th >Shipment Status</th>
                        <th >Return Tracking No.</th>
                        <th >Return Status</th>
                        <th >Return Date</th>
                        <th>Note</th>
                        <th >Added On</th>
                        <th >Last Updated On</th>
                    </tr>
                </thead>
                <tbody class="locDataTable">
                    <tr *ngFor="let ship of shippingData; let i = index ">
                        <td>{{(page-1) * pageSize + (i+1)}}</td>
                        <td>{{partNo}}</td>
                        <td>{{serialNo}}</td>
                        <td>{{ship.ticketNo}}</td>
                        <td>{{inventoryDesc}}</td>
                        <td>{{ship.shippingDate | date : 'MM/dd/yyyy HH:mm:ss'}}</td>
                        <td>
                            <span>
                                <span *ngFor="let loc of locationData">
                                    <span *ngIf="loc.locationId == ship.shippedTo">{{platformName}},  {{loc.locationName}}</span>
                                </span>
                            </span>
                        </td>
                        <td>{{ship.trackingNo}}</td>
                        <td>
                            <span>
                                <span *ngFor="let status of shippingStatus">
                                    <span *ngIf="ship.shippingStatusId == status.statusId">{{status.statusValue}}</span>
                                </span>
                            </span>
                        </td>
                        <td>{{ship.returnTrackNo}}</td>
                        <td>
                            <span *ngFor="let status of shippingReturnStatus">
                                <span *ngIf="ship.returnStatusId == status.statusId">{{status.statusValue}}</span>
                            </span>
                        </td>
                        <td>{{ship.returnDate | date : 'MM/dd/yyyy HH:mm:ss'}}</td>
                        <td>{{ship.note}}</td>
                        <td>{{ship.createdDate | date : 'MM/dd/yyyy HH:mm:ss'}}</td>
                        <td>{{ship.lastStmtDate | date : 'MM/dd/yyyy HH:mm:ss'}}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            <div *ngIf="shippingData.length == 0" class="no-data pb-2">
                No Data Available
            </div>
        </div>
    </mat-card>
</div>