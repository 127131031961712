import { Injectable } from '@angular/core';
import { Constants } from '../constants/constants';
import { HttpMiddleLayerService } from './http-middle-layer.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private middleLayer: HttpMiddleLayerService) { }

  getStatusDetails(){
    const url = Constants.API.url +`statuses`;
    return  this.middleLayer.get(url, null, null, null);
  }

  addStatusDetails(data){
    const url = Constants.API.url + `addStatus`;
    return  this.middleLayer.post(url,data,null,null,null);
  }

  updateStatusDetails(data,id){
    const pathParam = [id];
    const url = Constants.API.url + 'status/{0}';
    return  this.middleLayer.put(url,data,null,pathParam,null)
  }

  deleteStatusDetails(id){
    const pathParam = [id];
    const url = Constants.API.url + 'status/{0}';
    return  this.middleLayer.delete(url,null,pathParam,null)
  }

}
