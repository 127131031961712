import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { CommonService } from 'src/app/services/common.service';
import { ConfirmDailogComponent, ConfirmDialogModel } from 'src/app/shared/confirm-dailog/confirm-dailog.component';
import { JSON2SheetOpts } from 'xlsx/types';
import * as XLSX from 'xlsx';
import { StatusService } from 'src/app/services/status.service';
import { ImportFileComponent } from 'src/app/shared/import-file/import-file.component';
import { DeviceService } from 'src/app/services/device.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { PlatformService } from 'src/app/services/platform.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { LocationService } from 'src/app/services/location.service';
import { Observable } from "rxjs";
import { CanDeactivate } from '@angular/router';

export class RmaDeactivateGuard implements CanDeactivate<RmaComponent> {
  canDeactivate(component: RmaComponent) {
    if(component.canD_flag){
      return component.canDeactivate()
    }
    else
      return true;
  }
}

export interface Rma{
  "rmaId": number,
  "rmaStatusId": number,
  "hnsRma": string,
  "mfgRma": string,
  "ticketNo": string,
  "platformId": number,
  "inventoryId": number,
  "serialNo": string,
  "fault": string,
  "location": number,
  "createdDate": string,
  "addedByUser": string,
  "lastStmtDate": string,
  "lastStmtUser": string,
  "note":string,
  "partNo": string,
  "partDesc": string
}

@Component({
  selector: 'app-rma',
  templateUrl: './rma.component.html',
  styleUrls: ['./rma.component.css']
})
export class RmaComponent implements OnInit {

  rmaData : any[] = [];
  sortedData : Rma[] = [];
  prestineRmaData : any[] = [];
  searchInput : String = '';
  rmaStatus : any[] = [];
  statusData : any[] = [];
  deviceData : any[] = [];
  platformData : any[] = [];
  inventoryData : any[] = [];
  locationData : any[] = [];

  showError: boolean = false;
  errorMessage: any = [];
  errorCount: number = 0;
  dataSubmitted: boolean = false;
  isUpdated: boolean = false;
  isAdded: boolean = false;

  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";
  role : string = '';

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  canD_flag : boolean = false;

  constructor(private logger : NGXLogger, public dialog: MatDialog, private commonService : CommonService, private statusService : StatusService, private deviceService : DeviceService, private _snackBar: MatSnackBar, private platformService : PlatformService, private inventoryService : InventoryService, private locationService : LocationService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getLocationDetails();
    this.getStatusDetails();
    this.getPlatformDetails();
    this.getDeviceData();
  }

  getRmaData(){
    this.inventoryService.getAllRma().subscribe((data : any)=>{
      if(data){
        this.searchInput = '';
        this.searching(this.searchInput);
        this.logger.debug("RMA data: ",data);
        this.rmaData = data.responseData.rmas;
        this.rmaData.forEach((rma)=>{
          rma.originalRmaId = rma.rmaId;
          if(rma.platformId){
            rma.locationArray = [];
            let locationDropdownData = [];
            this.locationData.forEach((loc: any)=>{
              if(loc.platformId == rma.platformId){
                locationDropdownData.push(loc);
              }
            })
            rma.locationArray = locationDropdownData;
          }
        });
        this.sortedData = this.rmaData.slice();
        this.prestineRmaData = JSON.parse(JSON.stringify(this.rmaData));
      }

    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  confirmDeleteDialog(data, index): void {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete the rma data?');
    const dialogRef = this.dialog.open(ConfirmDailogComponent, {
      maxWidth: '500px',
      data: dialogData,
      panelClass: 'dialog-box'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        if(!data.rmaId){
          this.rmaData.splice(index, 1);
          this.sortedData.splice(index, 1);
          if(this.pageSize > 20  ){
            this.pageSize = this.rmaData.length;
          }
        }
        if(data.rmaId) {
          this.inventoryService.deleteRmaDetails(data.rmaId).subscribe(
            async (result: any) => {
              await result;
              this.dataSubmitted = true;
              this.rmaData.splice(index, 1);
              this.sortedData.splice(index, 1);
              if(this.pageSize > 20  ){
                this.pageSize = this.rmaData.length;
              }
              let prestineIndex = this.prestineRmaData.findIndex((prestineRma)=>prestineRma.rmaId == data.rmaId);
              if(prestineIndex !== -1){
                this.prestineRmaData.splice(prestineIndex, 1);
              }
            },
            async (error: any) => {
              await error;
              this.logger.error(error);

              this.errorCount++;
              if(error.responseCode){
                this.showError = true;
                this.errorMessage.push(error.responseMessage);
              }
            }
          )
        }
      }
    });
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }

  setPageValue() {
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount, this.rmaData.length)
  }

  // sorting
  sortData(sort: Sort) {
    const data = this.rmaData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'rmaStatusId': return this.commonService.compare(a.rmaStatusId, b.rmaStatusId, isAsc);
        case 'hnsRma': return this.commonService.compare(a.hnsRma.toUpperCase(), b.hnsRma.toUpperCase(), isAsc);
        case 'mfgRma': return this.commonService.compare(a.mfgRma.toUpperCase(), b.mfgRma.toUpperCase(), isAsc);
        case 'ticketNo': return this.commonService.compare(a.ticketNo.toUpperCase(), b.ticketNo.toUpperCase(), isAsc);
        case 'fault': return this.commonService.compare(a.fault.toUpperCase(), b.fault.toUpperCase(), isAsc);
        case 'note': return this.commonService.compare(a.note, b.note, isAsc);
        case 'createdDate': return this.commonService.compareDate(a.createdDate, b.createdDate, isAsc);
        case 'lastStmtDate': return this.commonService.compareDate(a.lastStmtDate, b.lastStmtDate, isAsc);
        case 'partNo': return this.commonService.compare(a.partNo.toUpperCase(), b.partNo.toUpperCase(), isAsc);
        case 'partDesc': return this.commonService.compare(a.partDesc, b.partDesc, isAsc);
        case 'serialNo': return this.commonService.compare(a.serialNo.toUpperCase(), b.serialNo.toUpperCase(), isAsc);
        case 'platformId': return this.commonService.compare(a.platformId, b.platformId, isAsc);
        case 'location': return this.commonService.compare(a.location, b.location, isAsc);
        default: return 0;
      }
    });
  }

  // method created to push updated values in an obj
  focusoutFunc(data, index) {
    this.canD_flag=true;
    // this.searchInput = '';
    let checkData = [];
    if(data.rmaId !== undefined || data.rmaId !== null){
      checkData = this.prestineRmaData.filter((item)=>{
        return data.rmaId === item.rmaId;
      })
      if(this.rmaData[index].isUpdated){
        delete this.rmaData[index].isUpdated;
      }
      if(checkData.length){
        if(checkData[0].isUpdated){
          delete checkData[0].isUpdated;
        }
      }
      if(JSON.stringify(data) !== JSON.stringify(checkData[0])) {
        this.rmaData[index].isUpdated = true;
      }
    }
  }

  addNew(){
    this.canD_flag=true;
    // this.searchInput = '';
    var newObj = {
      "rmaId": "",
      "rmaStatusId": "",
      "hnsRma": "",
      "mfgRma": "",
      "ticketNo": "",
      "platformId": "",
      "inventoryId": "",
      "serialNo": "",
      "fault": "",
      "location": "",
      "createdDate": "",
      "addedByUser": "",
      "lastStmtDate": "",
      "lastStmtUser": "",
      "note":"",
      "partNo": "",
      "partDesc": ""
    };
    this.rmaData.push(newObj);
    this.sortedData = this.rmaData.slice();
    if (this.pageSizeCount !== "pageAll") {
      this.page = this.commonService.pageChangeAddNew(this.pageSizeCount, this.rmaData.length);
    } else {
      this.pageSize = this.rmaData.length;
    }
  }

  export(){
    let data = JSON.parse(JSON.stringify(this.prestineRmaData));
    data.forEach(element => {
      this.rmaStatus.forEach(rma=>{
        if(element.rmaStatusId == rma.statusId){
          element.rmaStatusId = rma.statusValue;
        }
      });
      this.platformData.forEach(plat=>{
        if(element.platformId == plat.platformId){
          element.platformId = plat.platform;
        }
      });
      this.locationData.forEach(loc=>{
        if(element.location == loc.locationId){
          element.location = element.platformId+", "+loc.locationName;
        }
      })
    });
    let exportData = data.map( r => ({
      "Part-Number": r.partNo,
      "Serial Number": r.serialNo,
      "RMA Status": r.rmaStatusId,
      "HNS RMA": r.hnsRma,
      "Manufacturer RMA": r.mfgRma,
      "Ticket No.": r.ticketNo,
      "Platform": r.platformId,
      "Description" : r.partDesc,
      "Fault": r.fault,
      "Failure Location" : r.location,
      "Note":r.note,
      "Added on": r.createdDate,
      "Last Updated On": r.lastStmtDate,
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'allRmaList.xlsx');
  }

  getStatusDetails(){
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.statusData = data.responseData.status;
        this.commonService.sortArr(this.statusData, "statusValue");
        this.statusData.forEach(element => {
          if(element.statusKey.toUpperCase() == 'RMA'){
            this.rmaStatus.push(element);
          }
        });
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  searching(event){
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.prestineRmaData));
    this.rmaData = [];
    this.sortedData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.rmaId;
      delete newObj.originalRmaId;
      delete newObj.rmaStatusId;
      // delete newObj.platformId;
      this.platformData.forEach(plat=>{
        if(newObj.platformId == plat.platformId){
          newObj.platformId = plat.platform;
        }
      });
      delete newObj.location;
      delete newObj.addedByUser;
      delete newObj.lastStmtUser;
      delete newObj.inventoryId;
      delete newObj.locationArray;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.rmaData.push(data);
      }
    })
    this.sortedData = this.rmaData.slice();
  }

  importFile() {
    let obj = {
      filename : 'rma',
      fileProperty : 'file'
    }
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '750px',
      height: 'auto',
      maxHeight: '500px',
      panelClass: 'dialog-box',
      disableClose: true,
      data: obj
    });
  }

  getDeviceData(){
    this.deviceService.getDeviceDetails().subscribe((data : any)=>{
      if(data){
        this.deviceData = data.responseData.devices;
        this.logger.debug("Device data: ",this.deviceData);
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  checkPartNo(data, index){
    if(this.role != 'DET_Readonly'){
      let dataPresent = false;
      if(data.partNo){
        this.deviceData.forEach((device)=>{
          if(device.partNo.toUpperCase() == data.partNo.toUpperCase()){
            dataPresent = true;
            if(data.partNo && data.serialNo){
              this.inventoryService.checkInventory(data.partNo,data.serialNo).subscribe((InvData: any)=>{
                if(InvData){
                  if(InvData.responseData.inventoryId){
                    data.inventoryId = InvData.responseData.inventoryId;
                    data.partDesc = device.partDesc;
                    data.platformId = device.platformId;
                    let locationDropdownData = [];
                    if(data.platformId){
                      this.locationData.forEach((loc: any)=>{
                        if(loc.platformId == data.platformId){
                          locationDropdownData.push(loc);
                        }
                      })
                      data.locationArray = locationDropdownData;
                    }
                  }
                }
              }, (error)=>{
                this.logger.error(error);
                data.partNo = '';
                data.partDesc = '';
                data.platformId = '';
                data.location = '';
                data.serialNo = '';
                data.locationArray = [];
                if(error.responseCode){
                  this.showError = true;
                  this.errorMessage.push(error.responseMessage);
                }
                this.errorCount++;
              })
            }
          }
        });

        if(!dataPresent){
          this.openSnackBar("Please enter a valid device part no.", 'OK');
          data.locationArray = [];
          this.rmaData[index].partNo = '';
          this.sortedData[index].partNo = '';
          this.rmaData[index].partDesc = '';
          this.sortedData[index].partDesc = '';
          this.rmaData[index].platformId = '';
          this.sortedData[index].platformId = null;
          this.rmaData[index].location = '';
          this.sortedData[index].location = null;
        }
      }
    }
  }

  // alert message
  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  closeError(){
    this.errorMessage = [];
    this.showError = false;
  }

  saveRma(){
    this.canD_flag=false;
    if(this.role != 'DET_Readonly'){
      this.searchInput = '';
      let updatedData = [];
      let newData = [];
      this.showError = false;
      this.errorMessage = [];
      let errorMsg;
      this.errorCount = 0;
      this.dataSubmitted = false;

      for(let i=0; i< this.rmaData.length; i++){
        if(!this.rmaData[i].partNo || !this.rmaData[i].serialNo || !this.rmaData[i].rmaStatusId || !this.rmaData[i].ticketNo || !this.rmaData[i].platformId || !this.rmaData[i].fault || !this.rmaData[i].location){
          errorMsg = "You need to fill the complete data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(!this.rmaData[i].hnsRma && !this.rmaData[i].mfgRma){
          errorMsg = "HNS RMA or Manufacturer RMA should be required for Part No: " +this.rmaData[i].partNo+ ", Serial No: " + this.rmaData[i].serialNo+ " and Ticket No: "+ this.rmaData[i].ticketNo;
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(this.rmaData[i].isUpdated && this.rmaData[i].rmaId) {
          updatedData.push(this.rmaData[i])
        }
        if(!this.rmaData[i].rmaId) {
          newData.push(this.rmaData[i])
        }
      }

        // update service --------------------------------------------
        for (let i = 0; i < updatedData.length; i++) {
        let obj = {
          "rmaId" : updatedData[i].rmaId,
          "rmaStatusId": updatedData[i].rmaStatusId,
          "hnsRma" : updatedData[i].hnsRma,
          "mfgRma": updatedData[i].mfgRma,
          "ticketNo": updatedData[i].ticketNo,
          "fault": updatedData[i].fault,
          "locationId": updatedData[i].location,
          "platformId" : updatedData[i].platformId,
          "inventoryId": updatedData[i].inventoryId,
          "serialNo" : updatedData[i].serialNo,
          "note" : updatedData[i].note
        }

        this.inventoryService.updateRmaDetails(obj, updatedData[i].rmaId).subscribe(
          async (data: any) => {
            await data;
            for (let j = 0; j < this.rmaData.length; j++) {
              if (data.responseData.rmaId == this.rmaData[j].rmaId) {
                this.rmaData[j] = data.responseData;
                this.rmaData[j].originalRmaId = this.rmaData[j].rmaId;
                if(this.rmaData[j].platformId){
                  this.rmaData[j].locationArray = [];
                  let locationDropdownData = [];
                  this.locationData.forEach((loc: any)=>{
                    if(loc.platformId == this.rmaData[j].platformId){
                      locationDropdownData.push(loc);
                    }
                  })
                  this.rmaData[j].locationArray = locationDropdownData;
                }
                this.sortedData = this.rmaData.slice();
                this.prestineRmaData = JSON.parse(JSON.stringify(this.rmaData));
              }
            }
            this.dataSubmitted = true;
            this.isUpdated = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isUpdated = true;
          }
        )
      }
      updatedData = [];

      // add new api -----------------------------------------------------------------------
      for (let i = 0; i < newData.length; i++) {
        let obj = {
          "rmaId" : newData[i].rmaId,
          "rmaStatusId": newData[i].rmaStatusId,
          "hnsRma" : newData[i].hnsRma,
          "mfgRma": newData[i].mfgRma,
          "ticketNo": newData[i].ticketNo,
          "fault": newData[i].fault,
          "locationId": newData[i].location,
          "platformId" : newData[i].platformId,
          "inventoryId": newData[i].inventoryId,
          "serialNo" : newData[i].serialNo,
          "note" : newData[i].note,
          "partNo" : newData[i].partNo,
          "partDesc" : newData[i].partDesc
        }

        this.inventoryService.addRmaDetails(obj).subscribe(
          async (data: any) => {
            await data
            this.rmaData = this.rmaData.filter(function (data) {
              if (data.rmaId) {
                return data;
              }
            })

            let obj = data.responseData;
            obj.originalRmaId = obj.rmaId;
            if(obj.platformId){
              obj.locationArray = [];
              let locationDropdownData = [];
              this.locationData.forEach((loc: any)=>{
                if(loc.platformId == obj.platformId){
                  locationDropdownData.push(loc);
                }
              })
              obj.locationArray = locationDropdownData;
            }
            this.rmaData.push(obj);
            this.sortedData = this.rmaData.slice();
            this.prestineRmaData = JSON.parse(JSON.stringify(this.rmaData));
            this.dataSubmitted = true;
            this.isAdded = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);

            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isAdded = true;
          }
        )
      }
      newData = [];
    }
  }

  cancel(){
    this.canD_flag=false;
    this.showError = false;
    this.errorMessage = [];
    this.errorCount = 0;
    this.dataSubmitted = false;
    this.sortedData = [];
    this.prestineRmaData = [];
    this.rmaData = [];
    this.getRmaData();
  }

  getPlatformDetails(){
    this.platformService.getPlatformDetails().subscribe((data: any)=>{
      if(data){
        this.logger.debug("Platform data: ",data);
        this.platformData = data.responseData.platforms;
        this.commonService.sortArr(this.platformData, 'platform');
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

  getLocationDetails(){
    this.locationService.getLocationDetails().subscribe((data : any)=>{
      this.logger.debug("Location data: ",data);
      this.searchInput = '';
      this.locationData = data.responseData.locations;
      this.commonService.sortArr(this.locationData, "locationName");
      this.getRmaData();
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    //return confirm("Are you sure you want to leave the inventory data?")
    let thisObj = this;
    var confirmDialog = async function(){
      const dialogData = new ConfirmDialogModel('This page contains unsaved data. Are you sure you want to leave the page?');
      const dialogRef = thisObj.dialog.open(ConfirmDailogComponent, {
        maxWidth: '500px',
        data: dialogData,
        panelClass: 'dialog-box'
      });
      return await dialogRef.afterClosed().toPromise();
    }
    return confirmDialog();
  }
}
