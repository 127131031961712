import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { CommonService } from 'src/app/services/common.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { StatusService } from 'src/app/services/status.service';
import { ConfirmDailogComponent, ConfirmDialogModel } from 'src/app/shared/confirm-dailog/confirm-dailog.component';
import { JSON2SheetOpts } from 'xlsx/types';
import * as XLSX from 'xlsx';
import { ImportFileComponent } from 'src/app/shared/import-file/import-file.component';
import { LocationService } from 'src/app/services/location.service';

export interface Rma{
  "rmaId": number,
  "rmaStatusId": number,
  "hnsRma": string,
  "mfgRma": string,
  "ticketNo": string,
  "platformId": number,
  "inventoryId": number,
  "serialNo": string,
  "fault": string,
  "location": number,
  "createdDate": string,
  "addedByUser": string,
  "lastStmtDate": string,
  "lastStmtUser": string,
  "note":string,
}

@Component({
  selector: 'app-rma-list',
  templateUrl: './rma-list.component.html',
  styleUrls: ['./rma-list.component.css']
})
export class RmaListComponent implements OnInit {

  rmaStatus : any[] = [];
  statusData : any[] = [];
  searchInput: string = '';
  rmaData : any[] = [];
  sortedData : Rma[] = [];
  prestineRmaData : any[] = [];
  deleteData : any[] = [];
  locationData : any[] = [];

  showError: boolean = false;
  errorMessage: any = [];
  errorCount: number = 0;
  dataSubmitted: boolean = false;
  isUpdated: boolean = false;
  isAdded: boolean = false;

  inventoryId : string = "";
  platformName : string = "";
  platformId : string = "";
  serialNo : string = '';
  partNo : string = '';
  inventoryDesc : string = '';
  locationId : string = '';

  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";
  role : string = '';

  constructor(public dialogRef: MatDialogRef<RmaListComponent>, private statusService : StatusService, private logger : NGXLogger,  @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private inventoryService : InventoryService, private commonService : CommonService, public dialog: MatDialog, private locationService : LocationService) { this.inventoryId = data.inventoryId; this.platformName = data.platformName; this.platformId = data.platformId; this.serialNo = data.serialNo; this.partNo = data.partNo; this.inventoryDesc = data.inventoryDesc; this.locationId = data.locationId}

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getStatusDetails();
    this.getLocationDetails();
    if(this.inventoryId){
      this.getRmaData();
    }
  }

  cancel(){
    this.showError = false;
    this.errorMessage = [];
    this.errorCount = 0;
    this.dataSubmitted = false;
    this.sortedData = [];
    this.prestineRmaData = [];
    this.rmaData = [];
    this.deleteData = [];
    this.dialogRef.close();
  }

  export(){
    let data = JSON.parse(JSON.stringify(this.prestineRmaData));
    data.forEach(element => {
      this.rmaStatus.forEach(rma=>{
        if(element.rmaStatusId == rma.statusId){
          element.rmaStatusId = rma.statusValue;
        }
      });

      this.locationData.forEach(loc=>{
        if(loc.locationId == element.location){
          element.location = this.platformName+", "+loc.locationName;
        }
      })
    });
    let exportData = data.map( r => ({
      "Part-Number": this.partNo,
      "Serial Number": this.serialNo,
      "RMA Status": r.rmaStatusId,
      "HNS RMA": r.hnsRma,
      "Manufacturer RMA": r.mfgRma,
      "Ticket No.": r.ticketNo,
      "Platform": this.platformName,
      "Description" : this.inventoryDesc,
      "Fault": r.fault,
      "Failure Location": r.location,
      "Note":r.note,
      "Added on": r.createdDate,
      "Last Updated On": r.lastStmtDate,
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'rmaList.xlsx');
  }

  addNew(){
    // this.searchInput = '';
    var newObj = {
      "rmaId": "",
      "rmaStatusId": "",
      "hnsRma": "",
      "mfgRma": "",
      "ticketNo": "",
      "platformId": "",
      "inventoryId": "",
      "serialNo": "",
      "fault": "",
      "location": "",
      "createdDate": "",
      "addedByUser": "",
      "lastStmtDate": "",
      "lastStmtUser": "",
      "note":""
    };
    this.rmaData.push(newObj);
    this.sortedData = this.rmaData.slice();
    if (this.pageSizeCount !== "pageAll") {
      this.page = this.commonService.pageChangeAddNew(this.pageSizeCount, this.rmaData.length);
    } else {
      this.pageSize = this.rmaData.length;
    }
  }

  searching(event){
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.prestineRmaData));
    this.rmaData = [];
    this.sortedData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.rmaId;
      delete newObj.originalRmaId;
      delete newObj.rmaStatusId;
      delete newObj.platformId;
      delete newObj.location;
      delete newObj.addedByUser;
      delete newObj.lastStmtUser;
      delete newObj.inventoryId;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.rmaData.push(data);
      }
    })
    this.sortedData = this.rmaData.slice();
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }

  setPageValue() {
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount, this.rmaData.length)
  }

  // sorting
  sortData(sort: Sort) {
    const data = this.rmaData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'rmaStatusId': return this.commonService.compare(a.rmaStatusId, b.rmaStatusId, isAsc);
        case 'hnsRma': return this.commonService.compare(a.hnsRma.toUpperCase(), b.hnsRma.toUpperCase(), isAsc);
        case 'mfgRma': return this.commonService.compare(a.mfgRma.toUpperCase(), b.mfgRma.toUpperCase(), isAsc);
        case 'ticketNo': return this.commonService.compare(a.ticketNo.toUpperCase(), b.ticketNo.toUpperCase(), isAsc);
        case 'fault': return this.commonService.compare(a.fault.toUpperCase(), b.fault.toUpperCase(), isAsc);
        case 'location': return this.commonService.compare(a.location, b.location, isAsc);
        case 'note': return this.commonService.compare(a.note, b.note, isAsc);
        case 'createdDate': return this.commonService.compareDate(a.createdDate, b.createdDate, isAsc);
        case 'lastStmtDate': return this.commonService.compareDate(a.lastStmtDate, b.lastStmtDate, isAsc);
        default: return 0;
      }
    });
  }

  getStatusDetails(){
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.statusData = data.responseData.status;
        this.statusData.forEach(element => {
          if(element.statusKey.toUpperCase() == 'RMA'){
            this.rmaStatus.push(element);
          }
        });
        this.commonService.sortArr(this.rmaStatus, "statusValue");
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  getRmaData(){
    this.inventoryService.getRmaDetails(this.inventoryId).subscribe((data : any)=>{
      if(data){
        this.searchInput = '';
        this.searching(this.searchInput);
        this.logger.debug("RMA data: ",data);
        this.rmaData = data.responseData.rmas;
        this.rmaData.forEach((rma)=>{
          rma.originalRmaId = rma.rmaId;
        });
        this.sortedData = this.rmaData.slice();
        this.prestineRmaData = JSON.parse(JSON.stringify(this.rmaData));
      }
    },(error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  // method created to push updated values in an obj
  focusoutFunc(data, index) {
    // this.searchInput = '';
    let checkData = [];
    if(data.rmaId !== undefined || data.rmaId !== null){
      checkData = this.prestineRmaData.filter((item)=>{
        return data.rmaId === item.rmaId;
      })
      if(this.rmaData[index].isUpdated){ 
        delete this.rmaData[index].isUpdated;      
      }
      if(checkData.length){
        if(checkData[0].isUpdated){
          delete checkData[0].isUpdated;
        }
      }
      if(JSON.stringify(data) !== JSON.stringify(checkData[0])) {
        this.rmaData[index].isUpdated = true;
      }
    }
  }

  confirmDeleteDialog(data, index): void {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete the rma data?');
    const dialogRef = this.dialog.open(ConfirmDailogComponent, {
      maxWidth: '500px',
      data: dialogData,
      panelClass: 'dialog-box'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        if(!data.rmaId){
          this.rmaData.splice(index, 1);
          this.sortedData.splice(index, 1);
          if(this.pageSize > 20  ){
            this.pageSize = this.rmaData.length;
          }
        }
        if(data.rmaId) {
          // this.deleteData.push(data);
          this.inventoryService.deleteRmaDetails(data.rmaId).subscribe(
            async (result: any) => {
              await result;
              this.dataSubmitted = true;
              this.rmaData.splice(index, 1);
              this.sortedData.splice(index, 1);
              if(this.pageSize > 20  ){
                this.pageSize = this.rmaData.length;
              }
              let prestineIndex = this.prestineRmaData.findIndex((prestineRma)=>prestineRma.rmaId == data.rmaId);
              if(prestineIndex !== -1){
                this.prestineRmaData.splice(prestineIndex, 1);
              }
            },
            async (error: any) => {
              await error;
              this.logger.error(error);
    
              this.errorCount++;
              if(error.responseCode){
                this.showError = true;
                this.errorMessage.push(error.responseMessage);
              }
            }
          )
        }
      }
    });
  }

  saveRma(){
    if(this.role != 'DET_Readonly'){
      this.searchInput = '';
      let updatedData = [];
      let newData = [];
      this.showError = false;
      this.errorMessage = [];
      let errorMsg;
      this.errorCount = 0;
      this.dataSubmitted = false;
  
      for(let i=0; i<this.rmaData.length; i++){
        if(!this.rmaData[i].rmaStatusId || !this.rmaData[i].ticketNo || !this.rmaData[i].fault || !this.rmaData[i].location){
          errorMsg = "You need to fill the complete data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(!this.rmaData[i].hnsRma && !this.rmaData[i].mfgRma){
          errorMsg = "HNS RMA or Manufacturer RMA should be required for Ticket No: "+ this.rmaData[i].ticketNo;
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }
  
        if(this.rmaData[i].isUpdated && this.rmaData[i].rmaId) {
          updatedData.push(this.rmaData[i])
        }
        if(!this.rmaData[i].rmaId) {
          newData.push(this.rmaData[i])
        }
      }
  
       // update service --------------------------------------------
       for (let i = 0; i < updatedData.length; i++) {
        let obj = {
          "rmaId" : updatedData[i].rmaId,
          "rmaStatusId": updatedData[i].rmaStatusId,
          "hnsRma" : updatedData[i].hnsRma,
          "mfgRma": updatedData[i].mfgRma,
          "ticketNo": updatedData[i].ticketNo,
          "fault": updatedData[i].fault,
          "locationId": updatedData[i].location,
          "platformId" : updatedData[i].platformId,
          "inventoryId": updatedData[i].inventoryId,
          "serialNo" : updatedData[i].serialNo,
          "note" : updatedData[i].note
        }
  
        this.inventoryService.updateRmaDetails(obj, updatedData[i].rmaId).subscribe(
          async (data: any) => {
            await data;
            for (let j = 0; j < this.rmaData.length; j++) {
              if (data.responseData.rmaId == this.rmaData[j].rmaId) {
                this.rmaData[j] = data.responseData;
                this.rmaData[j].originalRmaId = this.rmaData[j].rmaId;
                this.sortedData = this.rmaData.slice();
                this.prestineRmaData = JSON.parse(JSON.stringify(this.rmaData));
              }
            }
            this.dataSubmitted = true;
            this.isUpdated = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isUpdated = true;
          }
        )
      }
      updatedData = [];
  
      // add new api -----------------------------------------------------------------------
      for (let i = 0; i < newData.length; i++) {
        let obj = {
          "rmaId" : newData[i].rmaId,
          "rmaStatusId": newData[i].rmaStatusId,
          "hnsRma" : newData[i].hnsRma,
          "mfgRma": newData[i].mfgRma,
          "ticketNo": newData[i].ticketNo,
          "fault": newData[i].fault,
          "locationId": newData[i].location,
          "platformId" : this.platformId,
          "inventoryId": this.inventoryId,
          "serialNo" : this.serialNo,
          "note" : newData[i].note
        }
  
        this.inventoryService.addRmaDetails(obj).subscribe(
          async (data: any) => {
            await data
            this.rmaData = this.rmaData.filter(function (data) {
              if (data.rmaId) {
                return data;
              }
            })
  
            let obj = data.responseData;
            obj.originalRmaId = obj.rmaId;
            this.rmaData.push(obj);
            this.sortedData = this.rmaData.slice();
            this.prestineRmaData = JSON.parse(JSON.stringify(this.rmaData));
            this.dataSubmitted = true;
            this.isAdded = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
  
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isAdded = true;
          }
        )
      }
      newData = [];
    }
  }

  closeError(){
    this.errorMessage = [];
    this.showError = false;
  }

  importFile() {
    let obj = {
      filename : 'rma',
      fileProperty : 'file'
    }
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '750px',
      height: 'auto',
      maxHeight: '500px',
      panelClass: 'dialog-box',
      disableClose: true,
      data: obj
    });
  }

  getLocationDetails(){
    this.locationService.getLocationDetails().subscribe((data : any)=>{
      this.logger.debug("Location data: ",data);
      this.searchInput = '';
      data.responseData.locations.forEach(element => {
        if(element.platformId == this.platformId){
          this.locationData.push(element);
        }
      });
      this.commonService.sortArr(this.locationData, "locationName");
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

}
