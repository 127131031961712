import { Injectable } from '@angular/core';
import { Constants } from '../constants/constants';
import { HttpMiddleLayerService } from './http-middle-layer.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private middleLayer: HttpMiddleLayerService) { }

  getDeviceDetails(){
    const url = Constants.API.url +`devices`;
    return  this.middleLayer.get(url, null, null, null);
  }

  addDeviceDetails(data){
    const url = Constants.API.url + `addDevice`;
    return  this.middleLayer.post(url,data,null,null,null);
  }

  updateDeviceDetails(data,id){
    const pathParam = [id];
    const url = Constants.API.url + 'device/{0}';
    return  this.middleLayer.put(url,data,null,pathParam,null)
  }

  deleteDeviceDetails(id){
    const pathParam = [id];
    const url = Constants.API.url + 'device/{0}';
    return  this.middleLayer.delete(url,null,pathParam,null)
  }

}
