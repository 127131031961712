<div class="modal-header">
    <div>
        File Attachment
    </div>
    <span (click)="onNoClick()" class="close ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"
            width="22px" height="22px" fill="#fff">
            <path
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
        </svg>
    </span>
</div>
<div class="lookup-container">
    <h4 class="lookupHeader" *ngIf="details">Upload file with filename which contains {{details.filename}}</h4>
    <h4>File format should be either .csv or .xls or .xlsx .</h4>
    <input type="file" class="mt-3 fileInput" #fileInput (change)="fileUpload(fileInput)"/>
    <h4 class="filename-error mt-2" *ngIf="filenameError">Please upload the file with valid filename</h4>
    <h4 class="filename-error mt-2" *ngIf="fileTypeError">Please upload the file with valid file format</h4>
    <h4 class="filename-error mt-2" *ngIf="serverError">{{serverError}}</h4>
    <h4 class="success-message mt-2" *ngIf="successMessage">File successfully uploaded.</h4>
    <div class="mt-5 col-xs-12 ">
        <button mat-flat-button class="btn-save mr-4 p-0" (click)="attachFileFunc(fileInput)" [disabled]="!disbaleBtn">Upload</button>
        <button mat-flat-button class="btn-save mr-4 p-0" (click)="cancelUpload(fileInput)" [disabled]="!disbaleBtn">Cancel</button>
    </div>
    <table class="table dt-table mt-3" *ngIf="finalErrorMsg.length">
        <thead>
            <tr>
                <th>S.No</th>
                <th>Error Message</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let err of finalErrorMsg">
                <td>{{err.sNo}}</td>
                <td>{{err.errorMsg}}</td>
            </tr>
        </tbody>
    </table>
</div>