import { Inject } from '@angular/core';
import { Component, ElementRef, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsPDF from 'jspdf'; 
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.css']
})
export class PrintComponent implements OnInit {

  @ViewChild('pdfTable') pdfTable: ElementRef;

  elementType = "svg";
  value : string = "";
  format = "CODE128";
  lineColor = "#000000";
  width = 2;
  height = 100;
  displayValue = true;
  fontOptions = "";
  font = "monospace";
  textAlign = "center";
  textPosition = "bottom";
  textMargin = 2;
  fontSize = 25;
  background = "#ffffff";
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;

  partNumber : string = "";
  serialNumber : string = "";
  platformName : string = "";
  asset : string = "";
  desc : string = "";

  constructor(public dialogRef: MatDialogRef<PrintComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { this.partNumber = data.partNo; this.serialNumber = data.serialNo; this.platformName = data.platformName; this.asset = data.assetTag; this.desc = data.desc }

  ngOnInit(): void {
    this.value = this.partNumber.concat(this.serialNumber);
  }

  cancel(){
    this.dialogRef.close();
  }

  get values(): string[] {
    return this.value.split("\n");
  }

  makePdf(){
    var data = this.pdfTable.nativeElement;  //Id of the table
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  
      let imgWidth = 225;   
      let pageHeight = 295;    
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      let heightLeft = imgHeight;  

      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jsPDF('l', 'mm', 'a4'); // A4 size page of PDF  
      let position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save('barcode.pdf'); // Generated PDF   
    });
  }

  printBarcode(){
    // let printContents, popupWin;
    // printContents = document.getElementById('print-section').innerHTML;
    // popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    // popupWin.document.open();
    // popupWin.document.write(`
    //   <html>
    //     <head>
    //       <title>Print tab</title>
    //       <style>
    //       //........Customized style.......
    //       </style>
    //     </head>
    // <body onload="window.print();window.close()">${printContents}</body>
    //   </html>`
    // );
    // // popupWin.document.close();
  }

}
