import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { CommonService } from '../../services/common.service';
import { PlatformService } from 'src/app/services/platform.service';
import * as XLSX from 'xlsx';
import { JSON2SheetOpts } from 'xlsx';
import { ConfirmDailogComponent, ConfirmDialogModel } from 'src/app/shared/confirm-dailog/confirm-dailog.component';
import { MatDialog } from '@angular/material/dialog';
import { StatusService } from 'src/app/services/status.service';
import { Observable } from "rxjs";
import { CanDeactivate } from '@angular/router';

export class PlatformDeactivateGuard implements CanDeactivate<PlatformComponent> {
  canDeactivate(component: PlatformComponent) {
    if(component.canD_flag){
      return component.canDeactivate()
    }
    else
      return true;
  }
}

export interface Platform{
  "platformId": number,
  "platform": String,
  "desc": String,
  "status": number,
  "addedOn": String,
  "updatedOn": String
}

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.css']
})
export class PlatformComponent implements OnInit {

  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string= "page10";
  searchInput : string = '';
  sortedData : Platform[] = [];
  perstinePlatformData : any[] = [];
  platformData : any[] = [];
  showError: boolean = false;
  errorMessage: any = [];
  errorCount: number = 0;
  dataSubmitted: boolean = false;
  platformStatus : any[] = [];
  isUpdated: boolean = false;
  isAdded: boolean = false;
  deleteData:any[] = [];
  statusData : any[] = [];
  role : string = '';
  canD_flag : boolean = false;

  constructor(private logger:NGXLogger, private platformService : PlatformService, private commonService: CommonService, public dialog: MatDialog, public statusService : StatusService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getStatusData();
    this.getPlatformData();
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }
  setMyPageValue(pageSize,arrLength){
    if(pageSize === "page10"){
      return 10;
    }
    else if(pageSize === "page20"){
      return 20;
    }
    else{
      return arrLength;
    }
  }
  setPageValue(){
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount,this.platformData.length)
  }

  // sorting
  sortData(sort: Sort) {
    const data = this.platformData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'status': return this.commonService.compare(a.status, b.status, isAsc);
        case 'platform': return this.commonService.compare(a.platform.toUpperCase(), b.platform.toUpperCase(), isAsc);
        case 'desc': return this.commonService.compare(a.desc, b.desc, isAsc);
        case 'addedOn': return this.commonService.compareDate(a.addedOn, b.addedOn, isAsc);
        case 'updatedOn': return this.commonService.compareDate(a.updatedOn, b.updatedOn, isAsc);
        default: return 0;
      }
    });
  }

  // add new platform
  addNew() {
    this.canD_flag=true;
    // this.searchInput = '';
    var newObj = {
      "platformId": '',
      "platform": "",
      "desc": "",
      "status": "",
      "addedOn": "",
      "updatedOn": ""
    };
    // this.perstinePlatformData.push(newObj);
    this.platformData.push(newObj);
    this.sortedData = this.platformData.slice();
    if (this.pageSizeCount !== "pageAll") {
      this.page = this.commonService.pageChangeAddNew(this.pageSizeCount, this.platformData.length);
    } else {
      this.pageSize = this.platformData.length;
    }
  }

  searching(event){
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.perstinePlatformData));
    this.platformData = [];
    this.sortedData = [];
    searchResultData.forEach((data)=>{
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.status;
      delete newObj.platformId;
      delete newObj.originalPlatformId;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if(newData){
        this.platformData.push(data);
      }
    })
    this.sortedData = this.platformData.slice();
  }

  export(){
    // this.searchInput = '';
    let data = JSON.parse(JSON.stringify(this.perstinePlatformData));
    data.forEach(element => {
      this.platformStatus.forEach(plat=>{
        if(element.status == plat.statusId){
          element.status = plat.statusValue;
        }
      })
    });
    let exportData = data.map( s => ({
      "Platform":s.platform,
      "Description":s.desc,
      "Status":s.status,
      "Added on":s.addedOn,
      "Last Updated On":s.updatedOn,
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'platform.xlsx');
  }

  getPlatformData(){
    this.platformService.getPlatformDetails().subscribe((data: any)=>{
      if(data){
        this.searchInput = '';
        this.searching(this.searchInput)
        this.logger.debug(data);
        this.platformData = data.responseData.platforms;
        for (let i = 0; i < this.platformData.length; i++) {
          this.platformData[i].originalPlatformId = this.platformData[i].platformId;
        }
        this.sortedData = this.platformData.slice();
        this.perstinePlatformData = JSON.parse(JSON.stringify(this.platformData));
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

  savePlatform(){
    this.canD_flag=false;
    if(this.role != 'DET_Readonly'){
      this.searchInput = '';
      let updatedData = [];
      let newData = [];
      this.showError = false;
      this.errorMessage = [];
      let errorMsg;
      this.errorCount = 0;
      this.dataSubmitted = false;

      for(let i=0; i<this.platformData.length; i++){
        if(this.platformData[i].platform === "" || this.platformData[i].status=== ""){
          errorMsg = "You need to fill the complete data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(this.platformData[i].isUpdated && this.platformData[i].platformId) {
          updatedData.push(this.platformData[i])
        }
        if(!this.platformData[i].platformId) {
          newData.push(this.platformData[i])
        }
      }

      // update service --------------------------------------------
      for (let i = 0; i < updatedData.length; i++) {
        let obj = {
          platform: updatedData[i].platform,
          desc: updatedData[i].desc,
          status: updatedData[i].status
        }

        this.platformService.updatePlatformDetails(obj, updatedData[i].platformId).subscribe(
          async (data: any) => {
            await data;
            for (let j = 0; j < this.platformData.length; j++) {
              if (data.responseData.platformId === this.platformData[j].platformId) {
                this.platformData[j] = data.responseData;
                this.platformData[j].originalPlatformId = this.platformData[j].platformId;
                this.sortedData = this.platformData.slice();
                this.perstinePlatformData = JSON.parse(JSON.stringify(this.platformData));
              }
            }
            this.dataSubmitted = true;
            this.isUpdated = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isUpdated = true;
          }
        )
      }
      updatedData = [];

      // add new api -----------------------------------------------------------------------
      for (let i = 0; i < newData.length; i++) {
        let obj = {
          platform: newData[i].platform,
          desc: newData[i].desc,
          status: newData[i].status
        }

        this.platformService.addPlatformDetails(obj).subscribe(
          async (data: any) => {
            await data
            this.platformData = this.platformData.filter(function (data) {
              if (data.platformId) {
                return data;
              }
            })

            let obj = data.responseData;
            obj.originalPlatformId = obj.platformId;
            this.platformData.push(obj);
            this.sortedData = this.platformData.slice();
            this.perstinePlatformData = JSON.parse(JSON.stringify(this.platformData));
            this.dataSubmitted = true;
            this.isAdded = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);

            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isAdded = true;
          }
        )
      }
      newData = [];
    }
  }

  // method created to push updated values in an obj
  focusoutFunc(data, index) {
    this.canD_flag= true;
    // this.searchInput = '';
    let checkData = [];
    if(data.platformId !== undefined || data.platformId !== null){
      checkData = this.perstinePlatformData.filter((item)=>{
        return data.platformId === item.platformId
      })
      if(this.platformData[index].isUpdated){
        delete this.platformData[index].isUpdated
      }
      if(checkData.length){
        if(checkData[0].isUpdated){
          delete checkData[0].isUpdated;
        }
      }
      if(JSON.stringify(data) !== JSON.stringify(checkData[0])) {
        this.platformData[index].isUpdated = true;
      }
    }
  }

  cancel(){
    this.canD_flag= false;
    this.showError = false;
    this.errorMessage = [];
    this.errorCount = 0;
    this.dataSubmitted = false;
    this.sortedData = [];
    this.perstinePlatformData = [];
    this.platformData = [];
    this.getPlatformData();
  }

  closeError(){
    this.errorMessage = [];
    this.showError = false;
  }

  confirmDeleteDialog(data, index): void {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete the platform data?');
    const dialogRef = this.dialog.open(ConfirmDailogComponent, {
      maxWidth: '500px',
      data: dialogData,
      panelClass: 'dialog-box'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.deleteData.push(data);
        this.platformData.splice(index, 1);
        this.sortedData.splice(index, 1);
      }
    })
  }

  getStatusData(){
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.statusData = data.responseData.status;
        this.statusData.forEach(element => {
          if(element.statusKey.toUpperCase() == 'PLATFORM'){
            this.platformStatus.push(element);
          }
        });
        this.commonService.sortArr(this.platformStatus, "statusValue");
      }
    }, (error)=>{
        this.logger.error(error);
    })
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    //return confirm("Are you sure you want to leave the inventory data?")
    let thisObj = this;
    var confirmDialog = async function(){
      const dialogData = new ConfirmDialogModel('This page contains unsaved data. Are you sure you want to leave the page?');
      const dialogRef = thisObj.dialog.open(ConfirmDailogComponent, {
        maxWidth: '500px',
        data: dialogData,
        panelClass: 'dialog-box'
      });
      return await dialogRef.afterClosed().toPromise();
    }
    return confirmDialog();
  }

}
