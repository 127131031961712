import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { CommonService } from 'src/app/services/common.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { StatusService } from 'src/app/services/status.service';
import { JSON2SheetOpts } from 'xlsx/types';
import * as XLSX from 'xlsx';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  inventoryId : string = "";
  platformName : string = "";
  platformId : string = "";
  serialNo : string = '';
  partNo : string = '';
  inventoryDesc : string = '';
  locationId : string = '';

  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";

  statusData : any[] = [];
  rmaStatus : any[] = [];
  rmaData : any[] = [];
  searchInput : string = '';
  prestineRmaData : any[] = [];
  shippingData : any[] = [];
  perstineShippingData : any[] = [];
  shippingReturnStatus : any[] = [];
  shippingStatus : any[] = [];
  searchShippingInput : string = '';
  locationData : any[] = [];


  constructor(public dialogRef: MatDialogRef<HistoryComponent>, private statusService : StatusService, private logger : NGXLogger,  @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private inventoryService : InventoryService, private commonService : CommonService, public dialog: MatDialog, private locationService : LocationService) {this.inventoryId = data.inventoryId; this.platformName = data.platformName; this.platformId = data.platformId; this.serialNo = data.serialNo; this.partNo = data.partNo; this.inventoryDesc = data.inventoryDesc; this.locationId = data.locationId }

  ngOnInit(): void {
    this.getStatusDetails();
    this.getLocationDetails();
    if(this.inventoryId){
      this.getRmaData();
      this.getShippingDetails();
    }
  }

  cancel(){
    this.dialogRef.close();
  }

  getStatusDetails(){
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.statusData = data.responseData.status;
        this.statusData.forEach(element => {
          if(element.statusKey.toUpperCase() == 'RMA'){
            this.rmaStatus.push(element);
          }
          if(element.statusKey.toUpperCase() == 'RETURN'){
            this.shippingReturnStatus.push(element);
          }
          if(element.statusKey.toUpperCase() == 'SHIPMENT'){
            this.shippingStatus.push(element);
          }
        });
      }
    }, (error)=>{
      this.logger.error(error);
    })
  }

  getRmaData(){
    this.inventoryService.getRmaDetails(this.inventoryId).subscribe((data : any)=>{
      if(data){
        this.searchInput = '';
        this.logger.debug("RMA data: ",data);
        this.rmaData = data.responseData.rmas;
        this.prestineRmaData = JSON.parse(JSON.stringify(this.rmaData));
      }
    },(error)=>{
      this.logger.error(error);
    })
  }

  getShippingDetails(){
    this.inventoryService.getShippingDetails(this.inventoryId).subscribe((data : any)=>{
      if(data){
        this.searchShippingInput = '';
        this.logger.debug("Shipping data: ",data);
        this.shippingData = data.responseData.shippings;
        this.perstineShippingData = JSON.parse(JSON.stringify(this.shippingData));
      }
    },(error)=>{
      this.logger.error(error);
    })
  }

  searchingRmaData(event){
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.prestineRmaData));
    this.rmaData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.rmaId;
      delete newObj.originalRmaId;
      delete newObj.rmaStatusId;
      delete newObj.platformId;
      delete newObj.location;
      delete newObj.addedByUser;
      delete newObj.lastStmtUser;
      delete newObj.inventoryId;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.rmaData.push(data);
      }
    })
  }

  searchingShippingData(event){
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.perstineShippingData));
    this.shippingData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.shippingId;
      delete newObj.originalShippingId;
      delete newObj.inventoryId;
      delete newObj.shippedTo;
      delete newObj.shippingStatusId;
      delete newObj.returnStatusId;
      delete newObj.lastStmtUser;
      delete newObj.addedByUser;
      delete newObj.platformId;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.shippingData.push(data);
      }
    })
  }

  exportRmaData(){
    let data = JSON.parse(JSON.stringify(this.prestineRmaData));
    data.forEach(element => {
      this.rmaStatus.forEach(rma=>{
        if(element.rmaStatusId == rma.statusId){
          element.rmaStatusId = rma.statusValue;
        }
      });
      this.locationData.forEach(loc=>{
        if(element.location == loc.locationId){
          element.location = this.platformName+", " +loc.locationName;
        }
      });
    });
    let exportData = data.map( r => ({
      "Part-Number": this.partNo,
      "Serial Number": this.serialNo,
      "RMA Status": r.rmaStatusId,
      "HNS RMA": r.hnsRma,
      "Manufacturer RMA": r.mfgRma,
      "Ticket No.": r.ticketNo,
      "Platform": this.platformName,
      "Description" : this.inventoryDesc,
      "Fault": r.fault,
      "Failure Location": r.location,
      "Note":r.note,
      "Added on": r.createdDate,
      "Last Updated On": r.lastStmtDate,
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'rmaList.xlsx');
  }

  exportShippingData(){
    let data = JSON.parse(JSON.stringify(this.perstineShippingData));
    data.forEach(element => {
      this.locationData.forEach(ship=>{
        if(element.shippedTo == ship.locationId){
          element.shippedTo = this.platformName+", " +ship.locationName;
        }
      });
      this.shippingStatus.forEach(shipp=>{
        if(element.shippingStatusId == shipp.statusId){
          element.shippingStatusId = shipp.statusValue;
        }
      });
      this.shippingReturnStatus.forEach(shipp=>{
        if(element.returnStatusId == shipp.statusId){
          element.returnStatusId = shipp.statusValue;
        }
      });
    });
    let exportData = data.map( r => ({
      "Part-Number": this.partNo,
      "Serial Number": this.serialNo,
      "Ticket No.": r.ticketNo,
      "Description":  this.inventoryDesc,
      "Date Shipping": r.shippingDate,
      "Shipped": r.shippedTo,
      "trackingNo" : r.trackingNo,
      "Shipment Status": r.shippingStatusId,
      "Return Tracking No.":r.returnTrackNo,
      "Return Status": r.returnStatusId,
      "Return Date":r.returnDate,
      "Note" : r.note,
      "Added on": r.createdDate,
      "Last Updated On": r.lastStmtDate,
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'shippingList.xlsx');
  }

  getLocationDetails(){
    this.locationService.getLocationDetails().subscribe((data : any)=>{
      this.logger.debug("Location data: ",data);
      data.responseData.locations.forEach(element => {
        if(element.platformId == this.platformId){
          this.locationData.push(element)
        }
      });
    }, (error)=>{
      this.logger.error(error);
    });
  }

}
