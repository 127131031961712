import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { CommonService } from 'src/app/services/common.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { LocationService } from 'src/app/services/location.service';
import { StatusService } from 'src/app/services/status.service';
import { ConfirmDailogComponent, ConfirmDialogModel } from 'src/app/shared/confirm-dailog/confirm-dailog.component';
import { JSON2SheetOpts } from 'xlsx/types';
import * as XLSX from 'xlsx';
import { ImportFileComponent } from 'src/app/shared/import-file/import-file.component';

export interface Shipping{
  "shippingId": number,
  "inventoryId": number,
  "shippingDate": string,
  "ticketNo": string,
  "trackingNo": number,
  "serialNo": string,
  "shippedTo": number,
  "shippingStatusId": number,
  "returnTrackNo": string,
  "returnStatusId": number,
  "returnDate": string,
  "createdDate": string,
  "addedByUser": string,
  "lastStmtDate": string,
  "lastStmtUser": string,
  "note":string
}

@Component({
  selector: 'app-shipping-list',
  templateUrl: './shipping-list.component.html',
  styleUrls: ['./shipping-list.component.css']
})
export class ShippingListComponent implements OnInit {

  searchInput: string = '';
  statusData : any[] = [];
  shippingReturnStatus : any[] = [];
  shippingStatus : any[] = [];
  locationData : any[] = [];
  shippingData : any[] = [];
  sortedData : Shipping[] = [];
  perstineShippingData : any[] = [];
  deleteData : any[] = [];

  showError: boolean = false;
  errorMessage: any = [];
  errorCount: number = 0;
  dataSubmitted: boolean = false;
  isUpdated: boolean = false;
  isAdded: boolean = false;

  inventoryId : string = "";
  platformName : string = "";
  platformId : string = "";
  serialNo : string = '';
  partNo : string = '';
  inventoryDesc : string = '';
  locationId : string = '';

  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";
  role : string = '';
  statusIds : any[] = [];

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialogRef: MatDialogRef<ShippingListComponent>,  @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private inventoryService : InventoryService, private statusService : StatusService, private logger : NGXLogger, private locationService : LocationService, private _snackBar: MatSnackBar, public dialog: MatDialog, private commonService : CommonService) { this.inventoryId = data.inventoryId; this.platformName = data.platformName; this.platformId = data.platformId; this.serialNo = data.serialNo; this.partNo = data.partNo; this.inventoryDesc = data.inventoryDesc; this.locationId = data.locationId}

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getStatusDetails();
    this.getLocationDetails();
    if(this.inventoryId){
      this.getShippingDetails();
    }
  }

  export(){
    let data = JSON.parse(JSON.stringify(this.perstineShippingData));
    data.forEach(element => {
      this.locationData.forEach(ship=>{
        if(element.shippedTo == ship.locationId){
          element.shippedTo = this.platformName+", " +ship.locationName;
        }
      });
      this.shippingStatus.forEach(shipp=>{
        if(element.shippingStatusId == shipp.statusId){
          element.shippingStatusId = shipp.statusValue;
        }
      });
      this.shippingReturnStatus.forEach(shipp=>{
        if(element.returnStatusId == shipp.statusId){
          element.returnStatusId = shipp.statusValue;
        }
      });
    });
    let exportData = data.map( r => ({
      "Part-Number": this.partNo,
      "Serial Number": this.serialNo,
      "Ticket No.": r.ticketNo,
      "Description":  this.inventoryDesc,
      "Date Shipping": r.shippingDate,
      "Shipped": r.shippedTo,
      "trackingNo" : r.trackingNo,
      "Shipment Status": r.shippingStatusId,
      "Return Tracking No.":r.returnTrackNo,
      "Return Status": r.returnStatusId,
      "Return Date":r.returnDate,
      "Note":r.note,
      "Added on": r.createdDate,
      "Last Updated On": r.lastStmtDate,
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'shippingList.xlsx');
  }

  addNew(){
    // this.searchInput = '';
    var newObj = {
      "shippingId": "",
      "inventoryId": "",
      "shippingDate": "",
      "ticketNo": "",
      "trackingNo": "",
      "serialNo": "",
      "shippedTo": "",
      "shippingStatusId": "",
      "returnTrackNo": "",
      "returnStatusId": "",
      "returnDate": "",
      "createdDate": "",
      "addedByUser": "",
      "note": "",
      "lastStmtDate": "",
      "lastStmtUser": ""
    };
    this.shippingData.push(newObj);
    this.sortedData = this.shippingData.slice();
    if (this.pageSizeCount !== "pageAll") {
      this.page = this.commonService.pageChangeAddNew(this.pageSizeCount, this.shippingData.length);
    } else {
      this.pageSize = this.shippingData.length;
    }
  }

  // sorting
  sortData(sort: Sort) {
    const data = this.shippingData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ticketNo': return this.commonService.compare(a.ticketNo.toUpperCase(), b.ticketNo.toUpperCase(), isAsc);
        case 'shippingDate': return this.commonService.compareDate(a.shippingDate, b.shippingDate, isAsc);
        case 'shippedTo': return this.commonService.compare(a.shippedTo, b.shippedTo, isAsc);
        case 'trackingNo': return this.commonService.compare(a.trackingNo.toUpperCase(), b.trackingNo.toUpperCase(), isAsc);
        case 'shippingStatusId': return this.commonService.compare(a.shippingStatusId, b.shippingStatusId, isAsc);
        case 'returnTrackNo': return this.commonService.compare(a.returnTrackNo, b.returnTrackNo, isAsc);
        case 'returnStatusId': return this.commonService.compare(a.returnStatusId, b.returnStatusId, isAsc);
        case 'note': return this.commonService.compare(a.note, b.note, isAsc);
        case 'createdDate': return this.commonService.compareDate(a.createdDate, b.createdDate, isAsc);
        case 'returnDate': return this.commonService.compareDate(a.returnDate, b.returnDate, isAsc);
        case 'lastStmtDate': return this.commonService.compareDate(a.lastStmtDate, b.lastStmtDate, isAsc);
        default: return 0;
      }
    });
  }

  searching(event){
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.perstineShippingData));
    this.shippingData = [];
    this.sortedData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.shippingId;
      delete newObj.originalShippingId;
      delete newObj.inventoryId;
      delete newObj.shippedTo;
      delete newObj.shippingStatusId;
      delete newObj.returnStatusId;
      delete newObj.lastStmtUser;
      delete newObj.addedByUser;
      delete newObj.platformId;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.shippingData.push(data);
      }
    })
    this.sortedData = this.shippingData.slice();
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }

  setPageValue() {
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount, this.shippingData.length)
  }

  getStatusDetails(){
    this.statusIds = [];
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.statusData = data.responseData.status;
        this.commonService.sortArr(this.statusData, "statusValue");
        this.statusData.forEach(element => {
          if(element.statusKey.toUpperCase() == 'RETURN'){
            this.shippingReturnStatus.push(element);
            let value = element.statusValue.replace(/\s/g, "").toUpperCase();
            if(value == 'NORETURN' || value == 'CANCELLED' || value == 'INTRANSIT'){
              this.statusIds.push(element.statusId);
            }
          }
          if(element.statusKey.toUpperCase() == 'SHIPMENT'){
            this.shippingStatus.push(element);
          }
        });
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  getLocationDetails(){
    this.locationService.getLocationDetails().subscribe((data : any)=>{
      this.logger.debug("Location data: ",data);
      data.responseData.locations.forEach(element => {
        if(element.platformId == this.platformId){
          this.locationData.push(element)
        }
      });
      this.commonService.sortArr(this.locationData, "locationName");
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

  // alert message
  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getShippingDetails(){
    this.inventoryService.getShippingDetails(this.inventoryId).subscribe((data : any)=>{
      if(data){
        this.searchInput = '';
        this.searching(this.searchInput);
        this.logger.debug("Shipping data: ",data);
        this.shippingData = data.responseData.shippings;
        this.shippingData.forEach((ship)=>{
          ship.originalShippingId = ship.shippingId;
        });
        this.sortedData = this.shippingData.slice();
        this.perstineShippingData = JSON.parse(JSON.stringify(this.shippingData));
      }
    },(error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  // method created to push updated values in an obj
  focusoutFunc(data, index) {
    // this.searchInput = '';
    let checkData = [];
    if(data.shippingId !== undefined || data.shippingId !== null){
      checkData = this.perstineShippingData.filter((item)=>{
        return data.shippingId === item.shippingId;
      })
      if(this.shippingData[index].isUpdated){ 
        delete this.shippingData[index].isUpdated;      
      }
      if(checkData.length){
        if(checkData[0].isUpdated){
          delete checkData[0].isUpdated;
        }
      }
      if(JSON.stringify(data) !== JSON.stringify(checkData[0])) {
        this.shippingData[index].isUpdated = true;
      }
    }
  }

  confirmDeleteDialog(data, index): void {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete the shipping data?');
    const dialogRef = this.dialog.open(ConfirmDailogComponent, {
      maxWidth: '500px',
      data: dialogData,
      panelClass: 'dialog-box'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        if(!data.shippingId){
          this.shippingData.splice(index, 1);
          this.sortedData.splice(index, 1);
          if(this.pageSize > 20  ){
            this.pageSize = this.shippingData.length;
          }
        }
        if (data.shippingId) {
          // this.deleteData.push(data);
          this.inventoryService.deleteShippingDetails(data.shippingId).subscribe(
            async (result: any) => {
              await result;
              this.dataSubmitted = true;
              this.shippingData.splice(index, 1);
              this.sortedData.splice(index, 1);
              if(this.pageSize > 20  ){
                this.pageSize = this.shippingData.length;
              }
              let prestineIndex = this.perstineShippingData.findIndex((prestineShip)=>prestineShip.shippingId == data.shippingId);
              if(prestineIndex !== -1){
                this.perstineShippingData.splice(prestineIndex, 1);
              }
            },
            async (error: any) => {
              await error;
              this.logger.error(error);
    
              this.errorCount++;
              if(error.responseCode){
                this.showError = true;
                this.errorMessage.push(error.responseMessage);
              }
            }
          )
        }
        // if(this.pageSize > 20  ){
        //   this.pageSize = this.shippingData.length;
        // }
        // this.perstineShippingData = JSON.parse(JSON.stringify(this.shippingData));
      }
    });
  }

  cancel(){
    this.showError = false;
    this.errorMessage = [];
    this.errorCount = 0;
    this.dataSubmitted = false;
    this.sortedData = [];
    this.perstineShippingData = [];
    this.shippingData = [];
    this.deleteData = [];
    this.dialogRef.close();
  }

  closeError(){
    this.errorMessage = [];
    this.showError = false;
  }

  saveShipping(){
    if(this.role != 'DET_Readonly'){
      this.searchInput = '';
      let updatedData = [];
      let newData = [];
      this.showError = false;
      this.errorMessage = [];
      let errorMsg;
      this.errorCount = 0;
      this.dataSubmitted = false;
  
      for(let i=0; i<this.shippingData.length; i++){
        if(!this.shippingData[i].ticketNo || !this.shippingData[i].shippingDate || !this.shippingData[i].shippedTo || !this.shippingData[i].trackingNo || !this.shippingData[i].shippingStatusId || !this.shippingData[i].returnStatusId){
          errorMsg = "You need to fill the complete data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(this.shippingData[i].returnStatusId && !this.shippingData[i].returnDate){
          let shipReturnStatusId = this.statusIds.includes(this.shippingData[i].returnStatusId);
          if(!shipReturnStatusId){
            errorMsg = "Enter the 'Return Date' if the 'Return Status' is not equal  to 'No Return, Cancelled, In Transit' for Ticket No:"+this.shippingData[i].ticketNo;
            this.showError = true;
            this.errorMessage.push(errorMsg);
            this.errorCount++;
            return;
          }
        }
  
        if(this.shippingData[i].isUpdated && this.shippingData[i].shippingId) {
          updatedData.push(this.shippingData[i])
        }
        if(!this.shippingData[i].shippingId) {
          newData.push(this.shippingData[i])
        }
      }
  
      // update service --------------------------------------------
      for (let i = 0; i < updatedData.length; i++) {
      let obj = {
        "inventoryId": updatedData[i].inventoryId,
        "shippingDate": updatedData[i].shippingDate,
        "ticketNo": updatedData[i].ticketNo,
        "trackingNo": updatedData[i].trackingNo,
        "serialNo": updatedData[i].serialNo,
        "shippedTo": updatedData[i].shippedTo,
        "shippingStatusId": updatedData[i].shippingStatusId,
        "returnTrackNo": updatedData[i].returnTrackNo,
        "returnStatusId": updatedData[i].returnStatusId,
        "returnDate": updatedData[i].returnDate,
        "note": updatedData[i].note
      }
  
      this.inventoryService.updateShippingDetails(obj, updatedData[i].shippingId).subscribe(
        async (data: any) => {
          await data;
          for (let j = 0; j < this.shippingData.length; j++) {
            if (data.responseData.shippingId == this.shippingData[j].shippingId) {
              this.shippingData[j] = data.responseData;
              this.shippingData[j].originalInventoryId = this.shippingData[j].shippingId;
              this.sortedData = this.shippingData.slice();
              this.perstineShippingData = JSON.parse(JSON.stringify(this.shippingData));
            }
          }
          this.dataSubmitted = true;
          this.isUpdated = false;
        },
        async (error: any) => {
          await error
          this.logger.error(error);
          if(error.responseCode){
            this.showError = true;
            this.errorMessage.push(error.responseMessage);
          }
          this.errorCount++;
          this.isUpdated = true;
        }
      )
    }
    updatedData = [];
  
      // add new api -----------------------------------------------------------------------
      for (let i = 0; i < newData.length; i++) {
        let obj = {
          "inventoryId": this.inventoryId,
          "shippingDate": newData[i].shippingDate,
          "ticketNo": newData[i].ticketNo,
          "trackingNo": newData[i].trackingNo,
          "serialNo": this.serialNo,
          "shippedTo": newData[i].shippedTo,
          "shippingStatusId": newData[i].shippingStatusId,
          "returnTrackNo": newData[i].returnTrackNo,
          "returnStatusId": newData[i].returnStatusId,
          "returnDate": newData[i].returnDate,
          "note": newData[i].note
        }
  
        this.inventoryService.addShippingDetails(obj).subscribe(
          async (data: any) => {
            await data
            this.shippingData = this.shippingData.filter(function (data) {
              if (data.shippingId) {
                return data;
              }
            })
  
            let obj = data.responseData;
            obj.shippingLocationId = obj.shippingId;
            this.shippingData.push(obj);
            this.sortedData = this.shippingData.slice();
            this.perstineShippingData = JSON.parse(JSON.stringify(this.shippingData));
            this.dataSubmitted = true;
            this.isAdded = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
  
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isAdded = true;
          }
        )
      }
      newData = [];
    }
  }

  importFile() {
    let obj = {
      filename : 'shipping',
      fileProperty : 'file'
    }
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '750px',
      height: 'auto',
      maxHeight: '500px',
      panelClass: 'dialog-box',
      disableClose: true,
      data: obj
    });
  }

  selectedStatus(id, date){
    if(!date){
      let selectedStatusId = this.statusIds.includes(id);
      if(!selectedStatusId){
        this.openSnackBar("Enter the Return Date",'OK');
      }
    }
  }

}
