<div class="modal-header">
    <div>
        Download Barcode
    </div>
    <span (click)="cancel()" class="close ">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"
            width="26px" height="26px" fill="#fff">
            <path
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
        </svg>
    </span>
</div>
<div class="center mt-3" *ngIf="value">
    <div class="center mr-3 ml-3">
        <div class="main-card-div" >
            <mat-card class="mb-3 card">
                <div class="pr-2 pl-2" #pdfTable>
                    <div class="row mr-0 ml-0 mb-3">
                        <div class="bardcode-header col-12">
                            {{platformName}}
                        </div>
                    </div>
                    <div class="row mr-0 ml-0">
                        <div class="col-6 border border-dark barcode-label">Part Number</div>
                        <div class="col-6 border border-dark barcode-label">{{partNumber}}</div>
                    </div>
                    <div class="row mr-0 ml-0">
                        <div class="col-6 border border-dark barcode-label">Serial Number</div>
                        <div class="col-6 border border-dark barcode-label">{{serialNumber}}</div>
                    </div>
                    <div class="row mr-0 ml-0">
                        <div class="col-6 border border-dark barcode-label">Description</div>
                        <div class="col-6 border border-dark barcode-label">{{desc}}</div>
                    </div>
                    <div class="row mr-0 ml-0">
                        <div class="col-6 border border-dark barcode-label">Asset</div>
                        <div class="col-6 border border-dark barcode-label">{{asset}}</div>
                    </div>
                    <div class="row mr-0 ml-0 mt-1">
                        <div class="col-12">
                            <ngx-barcode *ngFor="let bcValue of values" [bc-element-type]="elementType" [bc-value]="bcValue" [bc-format]="format" [bc-line-color]="lineColor"
                    [bc-width]="width" [bc-height]="height" [bc-display-value]="displayValue" [bc-font-options]="fontOptions" [bc-font]="font"
                    [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                    [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <button mat-raised-button class="mr-3 btn-save mt-2 mb-4" (click)="makePdf()">Download Barcode</button>
</div>
