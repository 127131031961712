import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { JSON2SheetOpts } from 'xlsx/types';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { ImportFileComponent } from 'src/app/shared/import-file/import-file.component';
import { ConfirmDailogComponent, ConfirmDialogModel } from '../../shared/confirm-dailog/confirm-dailog.component';
import { CommonService } from '../../services/common.service';
import { PlatformService } from 'src/app/services/platform.service';
import { LocationPopupComponent } from '../location-popup/location-popup.component';
import { DeviceService } from 'src/app/services/device.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { RmaListComponent } from '../rma-list/rma-list.component';
import { ShippingListComponent } from '../shipping-list/shipping-list.component';
import { StatusService } from 'src/app/services/status.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { PrintComponent } from '../print/print.component';
import { HistoryComponent } from '../history/history.component';
import { LocationService } from 'src/app/services/location.service';
import { Observable } from "rxjs";
import { CanDeactivate } from '@angular/router';

export class DeactivateGuard implements CanDeactivate<InventoryComponent> {
    canDeactivate(component: InventoryComponent) {
    if(component.canD_flag){
      return component.canDeactivate()
    }
    else
      return true;
  }
}

export interface Inventory {
  "inventoryId": number,
  "deviceId": number,
  "locationId": number,
  "availabilityStatusId": number,
  "serialNo": String,
  "partNo": String,
  "assetTag": String,
  "note": String,
  "inventoryDesc": String,
  "addedOn": String,
  "updatedOn": String
}

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  sortedData: any[]=[];
  perstineInvData: any[]=[];
  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";
  searchInput: string = '';
  deleteData:any[] = [];
  inventoryData : any = {};
  selectedPlatform : String = '';
  platformData : any[] = [];

  showError: boolean = false;
  errorMessage: any = [];
  errorCount: number = 0;
  dataSubmitted: boolean = false;
  isUpdated: boolean = false;
  isAdded: boolean = false;
  deviceData: any[] = [];
  statusData: any[] = [];
  availabilityStatus: any[] = [];
  selectedPlatformName: string = '';
  locationData : any[] = [];
  role : string = '';
  inactiveStatusId : any = '';
  newPlatformData: any[] = [];
  canD_flag: boolean = false;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private logger: NGXLogger, public dialog: MatDialog , private commonService: CommonService, private platformService : PlatformService, private deviceService : DeviceService, private _snackBar: MatSnackBar, private statusService : StatusService, private inventoryService : InventoryService, private locationService : LocationService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getDeviceData();
    this.getStatusData();
    this.getPlatformDetails();
    this.locationDetails();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    //return confirm("Are you sure you want to leave the inventory data?")
    let thisObj = this;
    var confirmDialog = async function(){
      const dialogData = new ConfirmDialogModel('This page contains unsaved data. Are you sure you want to leave the page?');
      const dialogRef = thisObj.dialog.open(ConfirmDailogComponent, {
        maxWidth: '500px',
        data: dialogData,
        panelClass: 'dialog-box'
      });
      return await dialogRef.afterClosed().toPromise();
    }
    return confirmDialog();
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }

  setPageValue() {
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount, this.inventoryData.length)
  }

  // sorting
  sortData(sort: Sort) {
    const data = this.inventoryData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'partNo': return this.commonService.compare(a.partNo.toUpperCase(), b.partNo.toUpperCase(), isAsc);
        case 'serialNo': return this.commonService.compare(a.serialNo.toUpperCase(), b.serialNo.toUpperCase(), isAsc);
        case 'inventoryDesc': return this.commonService.compare(a.inventoryDesc, b.inventoryDesc, isAsc);
        case 'availabilityStatusId': return this.commonService.compare(a.availabilityStatusId, b.availabilityStatusId, isAsc);
        case 'assetTag': return this.commonService.compare(a.assetTag.toUpperCase(), b.assetTag.toUpperCase(), isAsc);
        case 'note': return this.commonService.compare(a.note, b.note, isAsc);
        case 'addedOn': return this.commonService.compareDate(a.addedOn, b.addedOn, isAsc);
        case 'updatedOn': return this.commonService.compareDate(a.updatedOn, b.updatedOn, isAsc);
        default: return 0;
      }
    });
  }

  addNew() {
    this.canD_flag=true;
    if(this.selectedPlatform){
      // this.searchInput = '';
      var newObj = {
        "inventoryId": '',
        "deviceId": '',
        "locationId": '',
        "availabilityStatusId": '',
        "serialNo": "",
        "assetTag": "",
        "inventoryDesc": "",
        "addedOn": "",
        "updatedOn": "",
        "partNo":"",
        "note":""
      };
      this.inventoryData.push(newObj);
      this.sortedData = this.inventoryData.slice();
      if (this.pageSizeCount !== "pageAll") {
        this.page = this.commonService.pageChangeAddNew(this.pageSizeCount, this.inventoryData.length);
      } else {
        this.pageSize = this.inventoryData.length;
      }
    }
  }

  searching(event) {
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.perstineInvData));
    this.inventoryData = [];
    this.sortedData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.inventoryId;
      delete newObj.originalInventoryId;
      delete newObj.deviceId;
      delete newObj.availabilityStatusId;
      delete newObj.locationId;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.inventoryData.push(data);
      }
    })
    this.sortedData = this.inventoryData.slice();
  }

  confirmDeleteDialog(data, index): void {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete the inventory data?');
    const dialogRef = this.dialog.open(ConfirmDailogComponent, {
      maxWidth: '500px',
      data: dialogData,
      panelClass: 'dialog-box'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        if(!data.inventoryId){
          this.inventoryData.splice(index, 1);
          this.sortedData.splice(index, 1);
          if(this.pageSize > 20  ){
            this.pageSize = this.inventoryData.length;
          }
        }
        if (data.inventoryId) {
          this.inventoryService.deleteInventoryDetails(data.inventoryId).subscribe(
            async (result: any) => {
              await result;
              this.dataSubmitted = true;
              this.inventoryData.splice(index, 1);
              this.sortedData.splice(index, 1);
              if(this.pageSize > 20  ){
                this.pageSize = this.inventoryData.length;
              }
              let prestineIndex = this.perstineInvData.findIndex((prestineInv)=>prestineInv.inventoryId == data.inventoryId);
              if(prestineIndex !== -1){
                this.perstineInvData.splice(prestineIndex, 1);
              }
            },
            async (error: any) => {
              await error;
              this.logger.error(error);

              this.errorCount++;
              if(error.responseCode){
                this.showError = true;
                this.errorMessage.push(error.responseMessage);
              }
            }
          )
        }
      }
    });
  }

  export() {
    let data = JSON.parse(JSON.stringify(this.perstineInvData));
    data.forEach(element => {
      this.availabilityStatus.forEach(s=>{
        if(element.availabilityStatusId == s.statusId){
          element.availabilityStatusId = s.statusValue;
        }
      });
      this.locationData.forEach(loc=>{
        if(element.locationId == loc.locationId){
          element.locationId = loc.locationName+", "+this.selectedPlatformName;
        }
      })
    });
    let exportData = data.map( s => ({
      "Part-Number":s.partNo,
      "Serial Number":s.serialNo,
      "Description":s.inventoryDesc,
      "Availability Status":s.availabilityStatusId,
      "Asset":s.assetTag,
      "Note":s.note,
      "Current Location":s.locationId,
      "Added on":s.addedOn,
      "Last Updated On":s.updatedOn,
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'inventory.xlsx');
  }

  importFile() {
    let obj = {
      filename : 'inventory',
      fileProperty : 'file'
    }
    const dialogRef = this.dialog.open(ImportFileComponent, {
      width: '750px',
      height: 'auto',
      maxHeight: '500px',
      panelClass: 'dialog-box',
      disableClose: true,
      data: obj
    });
  }

  getPlatformDetails(){
    this.platformService.getPlatformDetails().subscribe((data: any)=>{
      if(data){
        this.logger.debug("Platform data: ",data);
        this.platformData = data.responseData.platforms;
        this.commonService.sortArr(this.platformData, 'platform');
        this.platformData.forEach((p)=>{
          if(p.status != this.inactiveStatusId){
            this.newPlatformData.push(p);
          }
        })
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

  getDeviceData(){
    this.deviceService.getDeviceDetails().subscribe((data : any)=>{
      if(data){
        this.deviceData = data.responseData.devices;
        this.logger.debug("Device data: ",this.deviceData);
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  checkPartNo(data, index){
    if(this.role != "DET_Readonly"){
      let dataPresent = false;
      this.deviceData.forEach((device)=>{
        if(this.selectedPlatform == device.platformId){
          if(device.partNo.toUpperCase() == data.partNo.toUpperCase()){
            dataPresent = true;
            data.inventoryDesc = device.partDesc;
          }
        }
      });

      if(!dataPresent){
        this.openSnackBar("Please enter a valid device part no.", 'OK');
        this.inventoryData[index].partNo = '';
        this.sortedData[index].partNo = '';
        this.inventoryData[index].inventoryDesc = '';
        this.sortedData[index].inventoryDesc = '';
      }
    }
  }

  // alert message
  openSnackBar(message, action) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getStatusData(){
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.statusData = data.responseData.status;
        this.commonService.sortArr(this.statusData, "statusValue");
        this.statusData.forEach(element => {
          if(element.statusKey.toUpperCase() == 'AVAILABILITY'){
            this.availabilityStatus.push(element);
          }
          if((element.statusKey.toUpperCase() == 'PLATFORM') && (element.statusValue.toUpperCase() == 'INACTIVE')){
            this.inactiveStatusId = element.statusId;
          }
        });
      }
    }, (error)=>{
        this.logger.error(error);
        if(error.responseCode){
          this.showError = true;
          this.errorMessage.push(error.responseMessage);
        }
        this.errorCount++;
    })
  }

  selectPlatform(){
    if(this.selectedPlatform){
    this.getPlatformName();
      this.inventoryService.getInventoryDetails(this.selectedPlatform).subscribe((data : any)=>{
        if(data){
          this.logger.debug("Inventory Data: ",data);
          this.searchInput = '';
          this.searching(this.searchInput);
          this.inventoryData = data.responseData.inventories;
          this.inventoryData.forEach((inv)=>{
            inv.originalInventoryId = inv.inventoryId;
          });
          this.sortedData = this.inventoryData.slice();
          this.perstineInvData = JSON.parse(JSON.stringify(this.inventoryData));
        }
      }, (error)=>{
          this.logger.error(error);
          if(error.responseCode){
            this.showError = true;
            this.errorMessage.push(error.responseMessage);
          }
          this.errorCount++;
      })
    }
  }

  loactionInformation(data, index){
    var obj  = {
      locationInfo : data.locationId,
      platform : this.selectedPlatform,
      serialNo : data.serialNo,
      partNo : data.partNo
    }
    const dialogRef = this.dialog.open(LocationPopupComponent, {
      width: '85%',
      height: '500px',
      maxHeight: '500px',
      panelClass: 'dialog-box',
      disableClose: true,
      data : obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        data.locationId = result.locationId;
        this.focusoutFunc(data,index);
      }
    });
  }

  rmaList(data, index){
    if(this.role != "DET_Readonly"){
      this.saveInventoryDetails();
    }
    if(data.inventoryId && this.errorMessage.length == 0){
      this.getPlatformName();
      var rmaObj = {
        platformId : this.selectedPlatform,
        platformName : this.selectedPlatformName,
        inventoryId : data.inventoryId,
        serialNo : data.serialNo,
        partNo : data.partNo,
        inventoryDesc : data.inventoryDesc,
        locationId : data.locationId,
      };

      const dialogRef = this.dialog.open(RmaListComponent, {
        width: '85%',
        height: '500px',
        maxHeight: '500px',
        panelClass: 'dialog-box',
        disableClose: true,
        data : rmaObj
      });
    }
  }

  shippingList(data, index){
    if(this.role != "DET_Readonly"){
      this.saveInventoryDetails();
    }
    if(data.inventoryId && this.errorMessage.length == 0){
      this.getPlatformName();
      var shippingObj = {
        platformId : this.selectedPlatform,
        platformName : this.selectedPlatformName,
        inventoryId : data.inventoryId,
        serialNo : data.serialNo,
        partNo : data.partNo,
        inventoryDesc : data.inventoryDesc,
        locationId : data.locationId,
      };

      const dialogRef = this.dialog.open(ShippingListComponent, {
        width: '85%',
        height: '500px',
        maxHeight: '500px',
        panelClass: 'dialog-box',
        disableClose: true,
        data : shippingObj
      });
    }
  }

  historyInfo(data, index){
    if(data.inventoryId){
      this.getPlatformName();
      var shippingObj = {
        platformId : this.selectedPlatform,
        platformName : this.selectedPlatformName,
        inventoryId : data.inventoryId,
        serialNo : data.serialNo,
        partNo : data.partNo,
        inventoryDesc : data.inventoryDesc,
        locationId : data.locationId,
      };

      const dialogRef = this.dialog.open(HistoryComponent, {
        width: '85%',
        height: '500px',
        maxHeight: '500px',
        panelClass: 'dialog-box',
        disableClose: true,
        data : shippingObj
      });
    }
  }

  print(data, index){
    this.getPlatformName();
    var obj  = {
      serialNo : data.serialNo,
      partNo : data.partNo,
      platformId : this.selectedPlatform,
      platformName : this.selectedPlatformName,
      assetTag : data.assetTag,
      desc : data.inventoryDesc
    }
    const dialogRef = this.dialog.open(PrintComponent, {
      width: '85%',
      height: '500px',
      maxHeight: '700px',
      panelClass: 'dialog-box',
      disableClose: true,
      data: obj
    });
  }

  closeError(){
    this.errorMessage = [];
    this.showError = false;
  }

  saveInventoryDetails(){
    this.canD_flag=false;
    if(this.role != "DET_Readonly"){
      this.searchInput = '';
      let updatedData = [];
      let newData = [];
      this.showError = false;
      this.errorMessage = [];
      let errorMsg;
      this.errorCount = 0;
      this.dataSubmitted = false;

      for(let i=0; i< this.inventoryData.length; i++){
        if(!this.inventoryData[i].partNo || !this.inventoryData[i].serialNo || !this.inventoryData[i].availabilityStatusId || !this.inventoryData[i].assetTag){
          errorMsg = "You need to fill the complete data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(!this.inventoryData[i].locationId){
          errorMsg = "You need to select the location data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(this.inventoryData[i].isUpdated && this.inventoryData[i].inventoryId) {
          updatedData.push(this.inventoryData[i])
        }
        if(!this.inventoryData[i].inventoryId) {
          newData.push(this.inventoryData[i])
        }
      }

      // update service --------------------------------------------
      for (let i = 0; i < updatedData.length; i++) {
        let obj = {
          "partNo": updatedData[i].partNo,
          "platformId" : this.selectedPlatform,
          "serialNo": updatedData[i].serialNo,
          "availabilityStatusId": updatedData[i].availabilityStatusId,
          "assetTag": updatedData[i].assetTag,
          "locationId": updatedData[i].locationId,
          "inventoryDesc" : updatedData[i].inventoryDesc,
          "note": updatedData[i].note
        }

        this.inventoryService.updateInventoryDetails(obj, updatedData[i].inventoryId).subscribe(
          async (data: any) => {
            await data;
            for (let j = 0; j < this.inventoryData.length; j++) {
              if (data.responseData.inventoryId === this.inventoryData[j].inventoryId) {
                this.inventoryData[j] = data.responseData;
                this.inventoryData[j].originalInventoryId = this.inventoryData[j].inventoryId;
                this.sortedData = this.inventoryData.slice();
                this.perstineInvData = JSON.parse(JSON.stringify(this.inventoryData));
              }
            }
            this.dataSubmitted = true;
            this.isUpdated = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isUpdated = true;
          }
        )
      }
      updatedData = [];

      // add new api -----------------------------------------------------------------------
      for (let i = 0; i < newData.length; i++) {
        let obj = {
          "partNo": newData[i].partNo,
          "platformId" : this.selectedPlatform,
          "serialNo": newData[i].serialNo,
          "availabilityStatusId": newData[i].availabilityStatusId,
          "assetTag": newData[i].assetTag,
          "locationId": newData[i].locationId,
          "inventoryDesc" : newData[i].inventoryDesc,
          "note": newData[i].note
        }

        this.inventoryService.addInventoryDetails(obj).subscribe(
          async (data: any) => {
            await data
            this.inventoryData = this.inventoryData.filter(function (data) {
              if (data.inventoryId) {
                return data;
              }
            })

            let obj = data.responseData;
            obj.originalInventoryId = obj.inventoryId;
            this.inventoryData.push(obj);
            this.sortedData = this.inventoryData.slice();
            this.perstineInvData = JSON.parse(JSON.stringify(this.inventoryData));
            this.dataSubmitted = true;
            this.isAdded = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);

            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isAdded = true;
          }
        )
      }
      newData = [];
    }

  }

  cancel(){
    this.canD_flag = false;
    this.showError = false;
    this.errorMessage = [];
    this.errorCount = 0;
    this.dataSubmitted = false;
    this.sortedData = [];
    this.perstineInvData = [];
    this.inventoryData = [];
    this.deleteData = [];
    this.selectPlatform();
  }

  // method created to push updated values in an obj
  focusoutFunc(data, index) {
    this.canD_flag=true;
    // this.searchInput = '';
    let checkData = [];
    if(data.inventoryId !== undefined || data.inventoryId !== null){
      checkData = this.perstineInvData.filter((item)=>{
        return data.inventoryId === item.inventoryId;
      })
      if(this.inventoryData[index].isUpdated){
        delete this.inventoryData[index].isUpdated;
      }
      if(checkData.length){
        if(checkData[0].isUpdated){
          delete checkData[0].isUpdated;
        }
      }
      if(JSON.stringify(data) !== JSON.stringify(checkData[0])) {
        this.inventoryData[index].isUpdated = true;
      }
    }
  }

  getPlatformName(){
    this.platformData.forEach(element => {
      if(element.platformId == this.selectedPlatform){
        this.selectedPlatformName = element.platform;
      }
    });
  }

  locationDetails(){
    this.locationService.getLocationDetails().subscribe((data : any)=>{
      this.logger.debug("Location data: ",data);
      this.locationData = data.responseData.locations;
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

}
