import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StringFormatterService } from './string-formatter.service';

@Injectable({
  providedIn: 'root'
})
export class HttpMiddleLayerService {

  response = 'response';

  handleError(error){
    if(error.error){
      return throwError(error.error);
    } else {
      return throwError("Oops!! Something went wrong");
    }

  }

  constructor(private http : HttpClient, private stringFormatter: StringFormatterService) { }
  
  //get request
  get<T>(url: string,  headerObject: any, pathParams : any[], queryParams: any[] ): Observable<any> {
    if(pathParams){
      url =  this.stringFormatter.format(url,pathParams)
    }
    
    if(queryParams){
      url = this.stringFormatter.queryParam(url,queryParams);
    }
      
    return this.http.get<any>(url , { headers: headerObject,observe: this.response as 'body'}).pipe(
      map((response) => {
        return response.body;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  // post request
  post<T>(url: string, data: any, headerObject: any, pathParams : any[], queryParams: any[]): Observable<any> {

    if(pathParams){
      url =  this.stringFormatter.format(url,pathParams)
    }
    
    if(queryParams){
      url = this.stringFormatter.queryParam(url,queryParams);
    }

    return this.http.post<any>(url, data,{ headers: headerObject, observe: this.response as 'body' })
      .pipe(map((response: any) => {
        return response.body;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  // delete request
  delete<T>(url: string, headerObject: any, pathParams: any[] , queryParams : any[]): Observable<any> {

    if(pathParams){
      url =  this.stringFormatter.format(url,pathParams)
    }
    
    if(queryParams){
      url = this.stringFormatter.queryParam(url,queryParams);
    }

    return this.http.delete<any>(url, { headers: headerObject, observe: this.response as 'body' })
      .pipe(map(response => {
        return response.body;
      }),
      catchError((error) => this.handleError(error))
    );
  }

  //put request
  put<T>(url: string, data: any, headerObject: any, pathParams: any[], queryParams : any[]): Observable<any> {
    if(pathParams){
      url =  this.stringFormatter.format(url,pathParams)
    }
    
    if(queryParams){
      url = this.stringFormatter.queryParam(url,queryParams);
    }

    return this.http.put<any>(url, data, { headers: headerObject, observe: this.response as 'body' })
      .pipe(map(response => {
        return response.body;
      }),
      catchError((error) => this.handleError(error))
    );
  }

}
