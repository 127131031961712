<app-dashboard></app-dashboard>
<div class="pb-4">
    <mat-card class="p-0 pb-4">
        <div class="col-md-12 deviceWrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="table-top-header">
                        <p class="float-left mt-1 m-0">Device</p>
                        <span class="float-right">
                            <input type="text" class= "mr-4" id="inputVal" placeholder="Search"
                                title="Type in a name" [(ngModel)]="searchInput" (keyup)="searching($event.target.value)">
                            <button mat-button class="tableHead-addButton mr-2" (click)="export()" [disabled]="perstineDeviceData.length == 0">
                                Export
                            </button>
                            <button mat-button class="tableHead-addButton mr-2" (click)="addNew()" *ngIf="role != 'DET_Readonly'">
                                Add +
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="tableOverflow">
            <table matSort (matSortChange)="sortData($event)" class="table dt-table" id="dataTable"> 
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th mat-sort-header="partNo">Part No.*</th>
                        <th mat-sort-header="platformId">Platform*</th>
                        <th mat-sort-header="partDesc">Description</th>
                        <th mat-sort-header="addedOn">Added On </th>
                        <th mat-sort-header="updatedOn">Last Modified On </th>
                        <th *ngIf="role == 'Admin'">Delete</th>
                    </tr>
                </thead>
                <tbody class="deviceDataTable">
                    <tr *ngFor="let deviceData of sortedData | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index ">
                        <td>{{(page-1) * pageSize + (i+1)}}</td>
                        <td>
                            <mat-form-field>
                                <input matInput [(ngModel)]="deviceData.partNo" (focusout)="focusoutFunc(deviceData,((page-1) * pageSize + i))" trim="blur" maxlength="50" required>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <mat-select [(ngModel)]="deviceData.platformId" required>
                                    <mat-option *ngFor="let platform of platformData" (focusout)="focusoutFunc(deviceData,((page-1) * pageSize + i))" [value]="platform.platformId">
                                        {{platform.platform}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput [(ngModel)]="deviceData.partDesc" (focusout)="focusoutFunc(deviceData,((page-1) * pageSize + i))" maxlength="255" trim="blur">
                            </mat-form-field>
                        </td>
                        <td>{{deviceData.addedOn | date : 'MM/dd/yyyy HH:mm:ss' }}</td>
                        <td>{{deviceData.updatedOn | date : 'MM/dd/yyyy HH:mm:ss' }}</td>
                        <td *ngIf="role == 'Admin'"><mat-icon class="delete-icon" (click)="confirmDeleteDialog(deviceData,((page-1) * pageSize + i))" matTooltip="Delete">delete</mat-icon></td>
                    </tr>
                </tbody>
            </table>
            </div>
            <div class="row"  *ngIf="sortedData.length">
                <div class="col-md-4 mt-3">
                    <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="sortedData.length"
                        [boundaryLinks]=true [maxSize]="3" [rotate]="true">
                    </ngb-pagination>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="text-center count-indicator">Showing {{(page-1) * pageSize + 1}} -
                        {{endCount(page,pageSize,sortedData.length)}}
                        of {{sortedData.length}} entries</div>
                </div>
                <div class="col-md-4 text-right mt-3">
                    <select class="custom-select text-right" style="width: auto" [(ngModel)]="pageSizeCount" (ngModelChange)="setPageValue()">
                        <option [ngValue]="'page10'">View 10</option>
                        <option [ngValue]="'page20'">View 20</option>
                        <option [ngValue]="'pageAll'">View All</option>
                    </select>
                </div>
            </div>
            <div *ngIf="errorMessage.length" class="errorDiv mt-3">
                <div class="float-left"><span *ngIf="errorMessage.length && dataSubmitted">
                    Data saved with the following Errors:
                  </span>
                  <ul>
                    <li *ngFor="let message of errorMessage">{{message}}</li>
                  </ul>
                </div>
                <div class="float-right" (click)="closeError()">
                  <svg _ngcontent-xsg-c336="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="14px" height="14px"
                    fill="#721c24" class="close-icon">
                    <path _ngcontent-xsg-c336=""
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                    </path>
                  </svg>
                </div>
            </div>
            <div class="row m-0 mt-1" *ngIf="sortedData.length || deviceDataDelete.length">
                <div class="col-md-12 p-0 text-right" *ngIf="role != 'DET_Readonly'">
                    <button mat-raised-button class="mr-3 btn-cancel" (click)="cancel()">Cancel</button>
                    <button mat-raised-button class="btn-save" (click)="saveDeviceData()">Save</button>
                </div>
            </div>
            <div *ngIf="sortedData.length == 0" class="no-data pb-2">
                No Data Available
            </div>
        </div>
    </mat-card>
</div>