import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req : HttpRequest<any>, next : HttpHandler){
    let requestData : any;
    requestData = req.clone();
    this.authService.startLoader.next(true);
    return next.handle(requestData).pipe(finalize(() => this.authService.startLoader.next(false))).pipe(
      catchError((error: HttpErrorResponse) => {
        this.authService.startLoader.next(false);
        return throwError(error);
      })
    )
  }
}
