import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { JSON2SheetOpts } from 'xlsx/types';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDailogComponent, ConfirmDialogModel } from '../../shared/confirm-dailog/confirm-dailog.component';
import { CommonService } from '../../services/common.service';
import { LocationService } from 'src/app/services/location.service';
import { PlatformService } from 'src/app/services/platform.service';
import { StatusService } from 'src/app/services/status.service';
import { Observable } from "rxjs";
import { CanDeactivate } from '@angular/router';

export class LocationDeactivateGuard implements CanDeactivate<LocationComponent> {
  canDeactivate(component: LocationComponent) {
    if(component.canD_flag){
      return component.canDeactivate()
    }
    else
      return true;
  }
}

export interface Location{
  "locationId": number,
  "platformId": number,
  "locationName": String,
  "operationStatus": number,
  "physicalAddress": String,
  "shippingAddress": String,
  "phoneNo": String,
  "website": String,
  "addedOn": String,
  "updatedOn": String
}

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  sortedData: Location[] = [];
  perstineLocationData: any[] = [];
  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";
  searchInput: string = '';
  deleteData:any[] = [];
  locationData: any[] = [];
  status : any[] = [];
  platformData : any[] = [];

  showError: boolean = false;
  errorMessage: any = [];
  errorCount: number = 0;
  dataSubmitted: boolean = false;
  isUpdated: boolean = false;
  isAdded: boolean = false;
  role : string = '';

  statusData : any[] = [];
  canD_flag : boolean = false;

  constructor(private logger: NGXLogger, public dialog: MatDialog , private commonService: CommonService, private locationService : LocationService, private platformService : PlatformService, private statusService : StatusService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getStatusData();
    this.getPlatformDetails();
    this.getLocation();
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }

  setPageValue() {
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount, this.locationData.length)
  }

   // sorting
  sortData(sort: Sort) {
    const data = this.locationData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'locationName': return this.commonService.compare(a.locationName.toUpperCase(), b.locationName.toUpperCase(), isAsc);
        case 'platformId' : return this.commonService.compare(a.platformId, b.platformId, isAsc);
        case 'operationStatus': return this.commonService.compare(a.operationStatus, b.operationStatus, isAsc);
        case 'physicalAddress': return this.commonService.compare(a.physicalAddress.toUpperCase(), b.physicalAddress.toUpperCase(), isAsc);
        case 'shippingAddress': return this.commonService.compare(a.shippingAddress, b.shippingAddress, isAsc);
        case 'phoneNo': return this.commonService.compare(a.phoneNo.toUpperCase(), b.phoneNo.toUpperCase(), isAsc);
        case 'website': return this.commonService.compare(a.website, b.website, isAsc);
        case 'addedOn': return this.commonService.compareDate(a.addedOn, b.addedOn, isAsc);
        case 'updatedOn': return this.commonService.compareDate(a.updatedOn, b.updatedOn, isAsc);
        default: return 0;
      }
    });
  }

  addNew() {
    this.canD_flag=true;
    // this.searchInput = '';
    var newObj = {
      "locationName": "",
      "platformId": '' ,
      "operationStatus": "",
      "physicalAddress": "",
      "shippingAddress": "",
      "phoneNo": "",
      "website": "",
    };
    this.locationData.push(newObj);
    this.sortedData = this.locationData.slice();
    if (this.pageSizeCount !== "pageAll") {
      this.page = this.commonService.pageChangeAddNew(this.pageSizeCount, this.locationData.length);
    } else {
      this.pageSize = this.locationData.length;
    }
  }

  searching(event) {
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.perstineLocationData));
    this.locationData = [];
    this.sortedData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.locationId;
      delete newObj.originalLocationId;
      delete newObj.platformId;
      delete newObj.operationStatus;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.locationData.push(data);
      }
    })
    this.sortedData = this.locationData.slice();
  }

  confirmDeleteDialog(data, index): void {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete the location data?');
    const dialogRef = this.dialog.open(ConfirmDailogComponent, {
      maxWidth: '500px',
      data: dialogData,
      panelClass: 'dialog-box'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.deleteData.push(data);
        this.locationData.splice(index, 1);
        this.sortedData.splice(index, 1);
      }
    })
  }

  export() {
    let data = JSON.parse(JSON.stringify(this.perstineLocationData));
    data.forEach(element => {
      this.status.forEach(s=>{
        if(element.operationStatus == s.statusId){
          element.operationStatus = s.statusValue;
        }
      });
      this.platformData.forEach(plat=>{
        if(element.platformId == plat.platformId){
          element.platformId = plat.platform;
        }
      })
    });
    let exportData = data.map( l => ({
      "Location" : l.locationName,
      "Platform":l.platformId,
      "Operation Status":l.operationStatus,
      "Physical Address":l.physicalAddress,
      "Shipping Address":l.shippingAddress,
      "Phone No.":l.phoneNo,
      "Website":l.website,
      "Added on":l.addedOn,
      "Last Updated On":l.updatedOn,
    }));

    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'location.xlsx');
  }

  getLocation(){
    this.locationService.getLocationDetails().subscribe((data : any)=>{
      this.logger.debug("Location data: ",data);
      this.searchInput = '';
      this.searching(this.searchInput);
      this.locationData = data.responseData.locations;
      this.locationData.forEach((loc)=>{
        loc.originalLocationId = loc.locationId;
      });
      this.sortedData = this.locationData.slice();
      this.perstineLocationData = JSON.parse(JSON.stringify(this.locationData));
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

  // method created to push updated values in an obj
  focusoutFunc(data, index) {
    this.canD_flag=true;
    // this.searchInput = '';
    let checkData = [];
    if(data.locationId !== undefined || data.locationId !== null){
      checkData = this.perstineLocationData.filter((item)=>{
        return data.locationId === item.locationId;
      })
      if(this.locationData[index].isUpdated){
        delete this.locationData[index].isUpdated;
      }
      if(checkData.length){
        if(checkData[0].isUpdated){
          delete checkData[0].isUpdated;
        }
      }
      if(JSON.stringify(data) !== JSON.stringify(checkData[0])) {
        this.locationData[index].isUpdated = true;
      }
    }
  }

  saveLocation(){
    this.canD_flag=false;
    if(this.role != "DET_Readonly"){
      this.searchInput = '';
      let updatedData = [];
      let newData = [];
      this.showError = false;
      this.errorMessage = [];
      let errorMsg;
      this.errorCount = 0;
      this.dataSubmitted = false;

      for(let i=0; i< this.locationData.length; i++){
        if(this.locationData[i].locationName === "" || this.locationData[i].phoneNo === "" || this.locationData[i].operationStatus === "" || this.locationData[i].physicalAddress === "" || this.locationData[i].platformId == ''){
          errorMsg = "You need to fill the complete data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(this.locationData[i].isUpdated && this.locationData[i].locationId) {
          updatedData.push(this.locationData[i])
        }
        if(!this.locationData[i].locationId) {
          newData.push(this.locationData[i])
        }
      }

      // update service --------------------------------------------
      for (let i = 0; i < updatedData.length; i++) {
        let obj = {
          "locationName": updatedData[i].locationName,
          "platformId": updatedData[i].platformId,
          "operationStatus": updatedData[i].operationStatus,
          "physicalAddress": updatedData[i].physicalAddress,
          "shippingAddress": updatedData[i].shippingAddress,
          "phoneNo": updatedData[i].phoneNo,
          "website": updatedData[i].website,
        }

        this.locationService.updateLocationDetails(obj, updatedData[i].locationId).subscribe(
          async (data: any) => {
            await data;
            for (let j = 0; j < this.locationData.length; j++) {
              if (data.responseData.locationId === this.locationData[j].locationId) {
                this.locationData[j] = data.responseData;
                this.locationData[j].originalLocationId = this.locationData[j].locationId;
                this.sortedData = this.locationData.slice();
                this.perstineLocationData = JSON.parse(JSON.stringify(this.locationData));
              }
            }
            this.dataSubmitted = true;
            this.isUpdated = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isUpdated = true;
          }
        )
      }
      updatedData = [];

      // add new -----------------------------------------------------------------------
      for (let i = 0; i < newData.length; i++) {
        let obj = {
          "locationName": newData[i].locationName,
          "platformId": newData[i].platformId,
          "operationStatus": newData[i].operationStatus,
          "physicalAddress": newData[i].physicalAddress,
          "shippingAddress": newData[i].shippingAddress,
          "phoneNo": newData[i].phoneNo,
          "website": newData[i].website,
        };

        this.locationService.addLocationDetails(obj).subscribe(
          async (data: any) => {
            await data
            this.locationData = this.locationData.filter(function (data) {
              if (data.locationId) {
                return data;
              }
            })

            let obj = data.responseData;
            obj.originalLocationId = obj.locationId;
            this.locationData.push(obj);
            this.sortedData = this.locationData.slice();
            this.perstineLocationData = JSON.parse(JSON.stringify(this.locationData));
            this.dataSubmitted = true;
            this.isAdded = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isAdded = true;
          }
        )
      }
      newData = [];
    }
  }

  closeError(){
    this.errorMessage = [];
    this.showError = false;
  }

  getPlatformDetails(){
    this.platformService.getPlatformDetails().subscribe((data: any)=>{
      if(data){
        this.logger.debug("Platform data: ",data);
        this.platformData = data.responseData.platforms;
        this.commonService.sortArr(this.platformData, 'platform');
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

  cancel(){
    this.canD_flag=false;
    this.showError = false;
    this.errorMessage = [];
    this.errorCount = 0;
    this.dataSubmitted = false;
    this.sortedData = [];
    this.perstineLocationData = [];
    this.platformData = [];
    this.locationData = [];
    this.getLocation();
  }

  getStatusData(){
    this.statusService.getStatusDetails().subscribe((data : any)=>{
      if(data){
        this.logger.debug("Status data: ",data);
        this.statusData = data.responseData.status;
        this.statusData.forEach(element => {
          if(element.statusKey.toUpperCase() == 'OPERATIONAL'){
            this.status.push(element);
          }
        });
        this.commonService.sortArr(this.status, "statusValue");
      }
    }, (error)=>{
        this.logger.error(error);
        if(error.responseCode){
          this.showError = true;
          this.errorMessage.push(error.responseMessage);
        }
        this.errorCount++;
    })
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    //return confirm("Are you sure you want to leave the inventory data?")
    let thisObj = this;
    var confirmDialog = async function(){
      const dialogData = new ConfirmDialogModel('This page contains unsaved data. Are you sure you want to leave the page?');
      const dialogRef = thisObj.dialog.open(ConfirmDailogComponent, {
        maxWidth: '500px',
        data: dialogData,
        panelClass: 'dialog-box'
      });
      return await dialogRef.afterClosed().toPromise();
    }
    return confirmDialog();
  }

}
