import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { OktaAuthGuard, OktaCallbackComponent} from '@okta/okta-angular';

const CALLBACK_PATH = 'login/callback';
const pathExceptions = [ CALLBACK_PATH ];

const routes: Routes = [
  {path: CALLBACK_PATH,  component: OktaCallbackComponent },
  {path: 'dashboard',  loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [ OktaAuthGuard ]},
  {path: '', redirectTo: 'dashboard/platform', pathMatch: 'full', canActivate: [ OktaAuthGuard ] },
  {path: '**', component: PageNotFoundComponent }
];

const protectedRoutes = routes.filter(route => !pathExceptions.includes(route.path));
protectedRoutes.forEach(route => {
  route.canActivate = route.canActivate || [];
  route.canActivate.push(OktaAuthGuard);
});

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
