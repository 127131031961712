import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NGXLogger } from 'ngx-logger';
import { JSON2SheetOpts } from 'xlsx/types';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDailogComponent, ConfirmDialogModel } from '../../shared/confirm-dailog/confirm-dailog.component';
import { CommonService } from '../../services/common.service';
import { DeviceService } from 'src/app/services/device.service';
import { PlatformService } from 'src/app/services/platform.service';
import { Observable } from "rxjs";
import { CanDeactivate } from '@angular/router';

export class DeviceDeactivateGuard implements CanDeactivate<DeviceComponent> {
  canDeactivate(component: DeviceComponent) {
    if(component.canD_flag){
      return component.canDeactivate()
    }
    else
      return true;
  }
}

export interface Device {
  deviceId: number;
  partNo: string;
  platformId: number;
  partDesc: string;
  addedOn: string;
  updatedOn: string;
}

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {

  deviceData: any[] = [];
  collectionSize: any;
  page: any = 1;
  pageSize: any = 10;
  pageSizeCount: string = "page10";
  searchInput: string = '';
  sortedData: Device[] = [];
  perstineDeviceData: any[] = [];
  exportActive: boolean = false;
  platformData: any[] = [];
  deviceDataDelete: any[] = [];

  showError: boolean = false;
  errorMessage: any = [];
  errorCount: number = 0;
  dataSubmitted: boolean = false;
  isUpdated: boolean = false;
  isAdded: boolean = false;
  role : string = "";
  canD_flag : boolean = false;

  constructor(private logger: NGXLogger, public dialog: MatDialog , private commonService: CommonService, private deviceService: DeviceService, private platformService : PlatformService) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('Role');
    this.getPlatformDetails();
    this.getDeviceData();
  }

  endCount = function (page, pageSize, collectionSize) {
    return Math.min((page * pageSize), collectionSize);
  }

  setPageValue() {
    this.pageSize = this.commonService.setPageValue(this.pageSizeCount, this.deviceData.length)
  }

  // sorting
  sortData(sort: Sort) {
    const data = this.deviceData.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'partNo': return this.commonService.compare(a.partNo.toUpperCase(), b.partNo.toUpperCase(), isAsc);
        case 'platformId': return this.commonService.compare(a.platformId, b.platformId, isAsc);
        case 'partDesc': return this.commonService.compare(a.partDesc, b.partDesc, isAsc);
        case 'addedOn': return this.commonService.compareDate(a.addedOn, b.addedOn, isAsc);
        case 'updatedOn': return this.commonService.compareDate(a.updatedOn, b.updatedOn, isAsc);
        default: return 0;
      }
    });
  }

  addNew() {
    this.canD_flag=true;
    // this.searchInput = '';
    var newObj = {
      "partNo" : "",
      "partDesc" : "",
      "platformId" : '',
      "deviceId" : "",
      "addedOn": "",
      "updatedOn": ""
    };
    this.deviceData.push(newObj);
    this.sortedData = this.deviceData.slice();
    if (this.pageSizeCount !== "pageAll") {
      this.page = this.commonService.pageChangeAddNew(this.pageSizeCount, this.deviceData.length);
    } else {
      this.pageSize = this.deviceData.length;
    }
  }

  searching(event) {
    var newData;
    let searchResultData = JSON.parse(JSON.stringify(this.perstineDeviceData));
    this.deviceData = [];
    this.sortedData = [];
    searchResultData.forEach((data) => {
      let newObj = JSON.parse(JSON.stringify(data));
      delete newObj.deviceId;
      delete newObj.platformId;
      delete newObj.originalDeviceId;
      newData = JSON.stringify(Object.values(newObj)).toUpperCase().includes(event.toUpperCase());
      if (newData) {
        this.deviceData.push(data);
      }
    })
    this.sortedData = this.deviceData.slice();
  }

  confirmDeleteDialog(data, index): void {
    const dialogData = new ConfirmDialogModel('Are you sure you want to delete the device data?');
    const dialogRef = this.dialog.open(ConfirmDailogComponent, {
      maxWidth: '500px',
      data: dialogData,
      panelClass: 'dialog-box'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        if(!data.deviceId){
          this.deviceData.splice(index, 1);
          this.sortedData.splice(index, 1);
          if(this.pageSize > 20  ){
            this.pageSize = this.deviceData.length;
          }
        }

        if(data.deviceId) {
          this.deviceService.deleteDeviceDetails(data.deviceId).subscribe(
            async (result: any) => {
              await result;
              this.dataSubmitted = true;
              this.deviceData.splice(index, 1);
              this.sortedData.splice(index, 1);
              if(this.pageSize > 20  ){
                this.pageSize = this.deviceData.length;
              }
              let prestineIndex = this.perstineDeviceData.findIndex((prestineDev)=>prestineDev.deviceId == data.deviceId);
              if(prestineIndex !== -1){
                this.perstineDeviceData.splice(prestineIndex, 1);
              }

            },
            async (error: any) => {
              await error;
              this.logger.error(error);

              this.errorCount++;
              if(error.responseCode){
                this.showError = true;
                this.errorMessage.push(error.responseMessage);
              }
            }
          )
        }
      }
    });
  }

  export() {
    let data = JSON.parse(JSON.stringify(this.perstineDeviceData));
    data.forEach(element => {
      this.platformData.forEach(p=>{
        if(element.platformId == p.platformId){
          element.platformId = p.platform;
        }
      });
    });
    let exportData = data.map( d => ({
      "Part Number" : d.partNo,
      "Platform" : d.platformId,
      "Description" : d.partDesc,
      "Added On": d.addedOn,
      "Last Updated On": d.updatedOn
    }));
    let options: JSON2SheetOpts;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData, options);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'All Data Export');
    XLSX.writeFile(wb, 'device.xlsx');
  }

  getDeviceData(){
    this.deviceService.getDeviceDetails().subscribe((data : any)=>{
      if(data){
        this.searchInput = '';
        this.searching(this.searchInput);
        this.deviceData = data.responseData.devices;
        this.deviceData.forEach((loc)=>{
          loc.originalDeviceId = loc.deviceId;
        });
        this.logger.debug("Device data: ",this.deviceData);
        this.sortedData = this.deviceData.slice();
        this.perstineDeviceData = JSON.parse(JSON.stringify(this.deviceData));
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    })
  }

  getPlatformDetails(){
    this.platformService.getPlatformDetails().subscribe((data: any)=>{
      if(data){
        this.logger.debug("Platform data: ",data);
        this.platformData = data.responseData.platforms;
        this.commonService.sortArr(this.platformData, 'platform');
      }
    }, (error)=>{
      this.logger.error(error);
      if(error.responseCode){
        this.showError = true;
        this.errorMessage.push(error.responseMessage);
      }
      this.errorCount++;
    });
  }

  // method created to push updated values in an obj
  focusoutFunc(data, index) {
    this.canD_flag=true;
    // this.searchInput = '';
    let checkData = [];
    if(data.deviceId !== undefined || data.deviceId !== null){
      checkData = this.perstineDeviceData.filter((item)=>{
        return data.deviceId === item.deviceId;
      })
      if(this.deviceData[index].isUpdated){
        delete this.deviceData[index].isUpdated;
      }
      if(checkData.length){
        if(checkData[0].isUpdated){
          delete checkData[0].isUpdated;
        }
      }
      if(JSON.stringify(data) !== JSON.stringify(checkData[0])) {
        this.deviceData[index].isUpdated = true;
      }
    }
  }

  saveDeviceData(){
    this.canD_flag=false;
    if(this.role != "DET_Readonly"){
      this.searchInput = '';
      let updatedData = [];
      let newData = [];
      this.showError = false;
      this.errorMessage = [];
      let errorMsg;
      this.errorCount = 0;
      this.dataSubmitted = false;

      for(let i=0; i< this.deviceData.length; i++){
        if(this.deviceData[i].partNo === "" || !this.deviceData[i].platformId){
          errorMsg = "You need to fill the complete data";
          this.showError = true;
          this.errorMessage.push(errorMsg);
          this.errorCount++;
          return;
        }

        if(this.deviceData[i].isUpdated && this.deviceData[i].deviceId) {
          updatedData.push(this.deviceData[i])
        }
        if(!this.deviceData[i].deviceId) {
          newData.push(this.deviceData[i])
        }
      }

       // update service --------------------------------------------
      for (let i = 0; i < updatedData.length; i++) {
        let obj = {
          "partNo" : updatedData[i].partNo,
          "partDesc" : updatedData[i].partDesc,
          "platformId" : updatedData[i].platformId
        }

        this.deviceService.updateDeviceDetails(obj, updatedData[i].deviceId).subscribe(
          async (data: any) => {
            await data;
            for (let j = 0; j < this.deviceData.length; j++) {
              if (data.responseData.deviceId === this.deviceData[j].deviceId) {
                this.deviceData[j] = data.responseData;
                this.deviceData[j].originalDeviceId = this.deviceData[j].deviceId;
                this.sortedData = this.deviceData.slice();
                this.perstineDeviceData = JSON.parse(JSON.stringify(this.deviceData));
              }
            }
            this.dataSubmitted = true;
            this.isUpdated = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isUpdated = true;
          }
        )
      }
      updatedData = [];

      // add service -----------------------------------------------------------------------
      for (let i = 0; i < newData.length; i++) {
        let obj = {
          "partNo" : newData[i].partNo,
          "partDesc" : newData[i].partDesc,
          "platformId" : newData[i].platformId
        };

        this.deviceService.addDeviceDetails(obj).subscribe(
          async (data: any) => {
            await data
            this.deviceData = this.deviceData.filter(function (data) {
              if (data.deviceId) {
                return data;
              }
            })

            let obj = data.responseData;
            obj.originalDeviceId = obj.deviceId;
            this.deviceData.push(obj);
            this.sortedData = this.deviceData.slice();
            this.perstineDeviceData = JSON.parse(JSON.stringify(this.deviceData));
            this.dataSubmitted = true;
            this.isAdded = false;
          },
          async (error: any) => {
            await error
            this.logger.error(error);
            if(error.responseCode){
              this.showError = true;
              this.errorMessage.push(error.responseMessage);
            }
            this.errorCount++;
            this.isAdded = true;
          }
        )
      }
      newData = [];
    }
  }

  closeError(){
    this.errorMessage = [];
    this.showError = false;
  }

  cancel(){
    this.canD_flag=false;
    this.showError = false;
    this.errorMessage = [];
    this.errorCount = 0;
    this.dataSubmitted = false;
    this.sortedData = [];
    this.perstineDeviceData = [];
    this.platformData = [];
    this.deviceData = [];
    this.deviceDataDelete = [];
    this.getDeviceData();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    //return confirm("Are you sure you want to leave the inventory data?")
    let thisObj = this;
    var confirmDialog = async function(){
      const dialogData = new ConfirmDialogModel('This page contains unsaved data. Are you sure you want to leave the Page');
      const dialogRef = thisObj.dialog.open(ConfirmDailogComponent, {
        maxWidth: '500px',
        data: dialogData,
        panelClass: 'dialog-box'
      });
      return await dialogRef.afterClosed().toPromise();
    }
    return confirmDialog();
  }

}

